import React, { useEffect, useState } from 'react'
import { withRouter, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import * as Icon from 'react-feather'
import Axios from 'axios'

import { QuotationDetailAPI } from '../../api/Rfq/GetRfqDetail'
import { AcceptDeclineRfqAPI } from '../../api/Rfq/AcceptDeclineRfq'
import * as actions from '../../store/actions/index'
import toastCenter, { toastMessageType } from '../../shared/toastCenter'
import { defaultDisplayDate } from '../../shared/dateTimeHelper'
import Prompt from '../../components/Prompt/Prompt'

import './Rfq.scss'
import CommentAndVersion from './CommentsAndVersions/CommentsAndVersions'
import AddPriceModal from './AddPriceModal/AddPriceModal'
import Spinner from '../../components/Loader/Spinner/Spinner'
import BrokenLink from './BrokenLink/BrokenLink'
import AddShipment from './AddShipment/AddShipment'
import Attachment from './Attachment/Attachment'
// import { quotationItemService } from '../../services/quotationService/quotationItemService'

const roleConstant = ['ppm', 'psp']

const statusConstant = [
  { value: 'pending', label: 'Pending' },
  { value: 'on-quote', label: 'On Quote' },
  { value: 'in-review', label: 'In Review' },
  { value: 'accepted', label: 'Accepted' },
  { value: 'declined', label: 'Declined' },
  { value: 'expired', label: 'Expired' },
  { value: 'cancelled', label: 'Cancelled' },
]

const Quotation = ({
  apiStart,
  apiStop,
  isPromptShow,
  promptTitle,
  promptContent,
  promptActionOk,
  promptActionCancel,
  promptCancelCallback,
  buttonPromptType,
  promptChildComponent,
  promptType,
}) => {
  const [rfq, setRfq] = useState(null)
  const [activeVersion, setActiveVersion] = useState(null)
  const [isShowAddPriceModal, setIsShowAddPriceModal] = useState(false)
  const [isShowShipmentModal, setIsShowShipmentModal] = useState(false)
  const [psp, setPsp] = useState(null)
  const [client, setClient] = useState(null)
  const [user, setUser] = useState(null)
  const [detailListFull, setDetailListFull] = useState([])
  const [detailListCounter, setDetailListCounter] = useState(0)
  const [detailList, setDetailList] = useState([])
  const [role, setRole] = useState('psp')
  const [status, setStatus] = useState('pending')
  const [expiryDate, setExpiryDate] = useState(null)
  const [isLoadingPage, setIsLoadingPage] = useState(true)
  const [shipmentId, setShipmentId] = useState(null)
  const [refetch, setRefetch] = useState(true)
  const [quotation, setQuotation] = useState(null)
  const [attachments, setAttachment] = useState([])

  const handleShowMoreDetails = () => {
    if (detailListCounter === detailListFull.length) {
      setDetailListCounter(4)
    } else {
      setDetailListCounter(detailListFull.length)
    }
  }

  const generateStatus = (s, statusList) => {
    const status = statusList.filter((r) => r.value === s)
    return status[0]
  }

  useEffect(() => {
    setDetailList(detailListFull.slice(0, detailListCounter))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailListCounter])

  const { quotationId } = useParams()

  useEffect(() => {
    const quotationDetailApi = new QuotationDetailAPI()
    const onNext = (response) => {
      if (response.success) {
        setRole(response.data.type)
        setStatus(generateStatus(response.data.status, statusConstant))
        setRfq(response.data)
        setPsp(response.data.psp)
        setClient(response.data.client)
        setUser(response.data.user)
        setDetailListFull(response.data.formData)
        setExpiryDate(response.data.expiryDate)
        setShipmentId(response.data.shipmentId)
        setDetailListCounter(4)
        setQuotation(response.data.quotation)
        setAttachment(response.data.ppmAttachments)

        if (response.data.versions && response.data.versions.length > 0) {
          setActiveVersion(response.data.versions[0])
        }

        // quotationItemService.emitReload(response.data.quotation._id)
      }
    }
    const onComplete = () => {
      setIsLoadingPage(false)
    }
    const onError = () => {
      setIsLoadingPage(false)
    }

    quotationDetailApi.subscribe(quotationId, onNext, onComplete, onError)

    return () => {
      quotationDetailApi.unsubscribe()
    }
  }, [apiStart, apiStop, quotationId, refetch])

  const downloadAttachment = (attachment) => {
    Axios.get(attachment.path, { responseType: 'blob' })
      .then((response) => {
        const url = URL.createObjectURL(new Blob([response.data]))

        const anchor = document.createElement('a')
        anchor.href = url
        anchor.download = attachment.fileName
        anchor.click()

        URL.revokeObjectURL(url)
      })
      .catch((error) => {
        toastCenter.message(
          'Failed',
          'Failed while downloading attachment',
          toastMessageType.ERROR
        )
      })
  }

  const renderAttachments = (attachments) => {
    if (attachments.length === 0) {
      return null
    }

    return (
      <>
        {attachments.map((attachment, idx) => (
          <div
            key={idx}
            className="d-flex flex-row w-100 align-items-center attachments"
          >
            <img
              src={attachment.thumbnail}
              alt=""
              height={40}
              width={40}
              className="pr-2"
            />
            <div className="pr-2">
              <div>{attachment.fileName}</div>
              <div className="text-grey">{attachment.fileType}</div>
            </div>
            <Icon.Download
              width={18}
              height={18}
              className="ml-auto cursor-pointer"
              onClick={() => downloadAttachment(attachment)}
            />
          </div>
        ))}
      </>
    )
  }

  const handleUserRfqAction = (action) => {
    const rfqAcceptDeclineApi = new AcceptDeclineRfqAPI()
    const onNext = (response) => {
      if (response.success) {
        toastCenter.message('Status Updated', 'RFQ status has been updated.')
      }
    }
    const onComplete = () => {
      setRefetch((prevState) => !prevState)
    }
    const onError = () => {
      toastCenter.messageServerError()
    }

    rfqAcceptDeclineApi.subscribe(
      quotationId,
      action,
      onNext,
      onComplete,
      onError
    )

    return () => {
      rfqAcceptDeclineApi.unsubscribe()
    }
  }

  return (
    <div className="main-wrapper">
      {isLoadingPage ? (
        <Spinner />
      ) : rfq === null ? (
        <BrokenLink />
      ) : (
        <div className="container">
          <div className="section-header d-flex flex-row py-4 align-items-center">
            <div className="left-side">
              <span className="title-text pr-3">
                {psp && psp.fullName}, {psp && psp.company}
              </span>
              {status && ['on-quote', 'expired'].indexOf(status.value) > -1 && (
                <span className="due-date-text">{`[Expires on ${expiryDate}]`}</span>
              )}
              <div className="rfq-title-quotation">
                <p className="mb-0 font-weight-bold">
                  {quotation.title} / {quotation.quotationNumber}
                </p>
              </div>
              {/* // TODO : change into anchor button */}
              {/* <button className="btn btn-primary">Artwork</button> */}
            </div>
            <div className="ml-auto">
              <div
                className={`btn ${
                  status && status.value === 'declined'
                    ? 'btn-danger'
                    : 'btn-primary'
                } disabled text-white mr-4`}
              >
                {status && status.label}
              </div>

              {/* {role === roleConstant[0] &&
              status &&
              status.value === 'accepted' ? (
                <button
                  className="btn btn-outline-primary mx-2"
                  onClick={() => handleUserRfqAction('unapprove')}
                >
                  Unaccept
                </button>
              ) : null} */}
              {role === roleConstant[0] &&
              status &&
              status.value === statusConstant[2].value ? (
                <>
                  <button
                    className="btn btn-outline-primary mx-2"
                    onClick={() => handleUserRfqAction('accept')}
                  >
                    Accept
                  </button>
                  <button
                    className="btn btn-outline-danger mx-2"
                    onClick={() => handleUserRfqAction('decline')}
                  >
                    Decline
                  </button>
                </>
              ) : null}
            </div>
          </div>
          <hr />
          <div className="section-body row">
            <div className=" details-col col-7">
              <div className="address-row row">
                <div className="sender-col col-5">
                  <div>{psp && psp.address}</div>
                  <div>
                    {psp && psp.city} {psp && psp.state} {psp && psp.zipcode}
                  </div>
                  <div>{psp && psp.country}</div>
                  <div>{psp && psp.phone}</div>
                  <div className="mt-3">{psp && psp.email}</div>
                </div>

                <div className=" recipient-col col-7 row">
                  <div className="col-5 left-side">Submitted Date</div>
                  <div className="col-7 right-side">
                    {activeVersion !== null
                      ? defaultDisplayDate(new Date(activeVersion.createdAt))
                      : '-'}
                  </div>
                  <div className="col-5 left-side">Quotation for</div>
                  <div className="col-7 right-side">
                    <div>
                      {client && client.fullName}, {client && client.company}
                    </div>
                    <div>{client && client.address}</div>
                    <div>
                      {client && client.city} {client && client.state}{' '}
                      {client && client.zipcode}
                    </div>
                    <div>{client && client.country}</div>
                    <div>{client && client.phone}</div>
                    <div className="mt-3">{client && client.email}</div>
                  </div>
                  {role === roleConstant[1] &&
                  status.value === statusConstant[3].value ? (
                    shipmentId === null ? (
                      <div className="col-12">
                        <div
                          onClick={() => setIsShowShipmentModal(true)}
                          className="add-address"
                        >
                          [ Add Shipment ID ]
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="col-5 left-side">
                          <div>
                            <span>Shipment ID</span>

                            <Icon.Edit3
                              onClick={() => setIsShowShipmentModal(true)}
                              className="feather shipping-edit-icon"
                            />
                          </div>
                        </div>
                        <div className="col-7 right-side">{shipmentId}</div>
                      </>
                    )
                  ) : null}
                  {role === roleConstant[0] &&
                  status.value === statusConstant[3].value
                    ? shipmentId && (
                        <>
                          <div className="col-5 left-side">
                            <div>
                              <span>Shipment ID</span>
                            </div>
                          </div>
                          <div className="col-7 right-side">{shipmentId}</div>
                        </>
                      )
                    : null}
                </div>
              </div>
              <hr />
              <div className="detail-row row">
                <div className="title-col col-5">Details</div>
                <div className="value-col col-7 pr-4">
                  {detailList.map((d) => (
                    <div key={d.label} className="row underline">
                      <div className="left-side col-5">{d.label}</div>
                      <div className="right-side col-7">{d.value}</div>
                    </div>
                  ))}
                  <div className="show-more" onClick={handleShowMoreDetails}>
                    {detailList.length === detailListFull.length
                      ? 'Show Less'
                      : 'Show More'}
                  </div>
                </div>
              </div>
              <hr />
              <div className="price-row row">
                <div className="title-col col-5">
                  Price{' '}
                  {role === roleConstant[1] &&
                    status &&
                    status.value === statusConstant[2].value &&
                    rfq &&
                    rfq.versions &&
                    rfq.versions.length > 0 && (
                      <Icon.Edit3
                        width={18}
                        height={18}
                        className="ml-auto cursor-pointer"
                        onClick={() => setIsShowAddPriceModal(true)}
                      />
                    )}
                </div>
                <div className="col-7">
                  {role === roleConstant[0] &&
                  (status === statusConstant[1] ||
                    status === statusConstant[0]) ? (
                    <div className="no-price">
                      The Price will be available here when the quote has been
                      submitted by PSP.
                    </div>
                  ) : activeVersion === null ? (
                    <div
                      onClick={() => {
                        if (
                          ['on-quote', 'in-review'].indexOf(status.value) > -1
                        )
                          setIsShowAddPriceModal(true)
                      }}
                      className={`add-price${
                        ['declined', 'expired', 'cancelled'].indexOf(
                          status.value
                        ) > -1
                          ? ' disabled'
                          : ''
                      }`}
                    >
                      [ Add Price ]
                    </div>
                  ) : (
                    <div className="price-col d-flex flex-column">
                      <div className="d-flex flex-row justify-content-between underline">
                        <span className="left-side">Print Price</span>
                        <span>
                          {rfq.currency.symbol}
                          {parseFloat(activeVersion.price).toFixed(2)}
                        </span>
                      </div>
                      <div className="d-flex flex-row justify-content-between underline">
                        <span className="left-side">Shipping Price</span>
                        <span>
                          {rfq.currency.symbol}
                          {parseFloat(activeVersion.shippingPrice).toFixed(2)}
                        </span>
                      </div>
                      <div className="total-row d-flex flex-row justify-content-between underline">
                        <span>Total</span>
                        <span className="total-price">
                          {rfq.currency.symbol}
                          {parseFloat(activeVersion.totalPrice).toFixed(2)}
                        </span>
                      </div>

                      {activeVersion && activeVersion.estimatedTurnaroundTime && (
                        <div className="d-flex flex-row justify-content-between underline mb-2">
                          <span>Estimated Turnaround Time (Business Days)</span>
                          <span className="total-price">
                            {activeVersion.estimatedTurnaroundTime} days
                          </span>
                        </div>
                      )}

                      {/* // todo: change when activeVersion.note created */}
                      {activeVersion && activeVersion.note && (
                        <div className="note-row underline">
                          <span className="note-row">{activeVersion.note}</span>
                        </div>
                      )}

                      {/* {activeVersion.note !== null && (
                        <div>Note: {activeVersion.note}</div>
                      )} */}
                      {renderAttachments(activeVersion.attachments)}
                    </div>
                  )}
                </div>
              </div>
              <hr />
              <div className="attachment-row row">
                <div className="title-col col-5">RFQ Attachments</div>
                <div className="value-col col-7">
                  <Attachment
                    attachments={attachments}
                    userRole={role}
                    roles={roleConstant}
                    quotationPspId={quotationId}
                    downloadAttachment={downloadAttachment}
                    activeVersion={activeVersion}
                    setRefetch={setRefetch}
                  />
                </div>
              </div>
              {/* <hr />
              <div className="row pb-4">
                <div className="col-5">Artwork</div>
                <div className="col-7">
                  {rfq.attachments && rfq.attachments.length > 0 ? (
                    renderAttachments(rfq.attachments)
                  ) : (
                    <div className="dotted-border">
                      <div className="empty">
                        <button className="btn btn-outline p-0">
                          Artwork still in progress
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div> */}
            </div>
            <div className="col-5">
              <CommentAndVersion
                apiStart={apiStart}
                apiStop={apiStop}
                quotationId={quotationId} // rfqId
                rfq={rfq}
                user={user}
                psp={psp}
                type={role}
                activeVersion={activeVersion}
                changeActiveVersion={setActiveVersion}
                refetch={refetch}
              />
            </div>
          </div>
        </div>
      )}
      <AddPriceModal
        quotationId={quotationId}
        show={isShowAddPriceModal}
        setShow={setIsShowAddPriceModal}
        setRefetch={setRefetch}
      />
      <AddShipment
        show={isShowShipmentModal}
        setShow={setIsShowShipmentModal}
        setShipment={setShipmentId}
        quotationOrderId={quotationId}
      />
      <Prompt
        show={isPromptShow}
        title={promptTitle}
        content={promptContent}
        onActionOK={promptActionOk}
        onActionCancel={promptActionCancel}
        btnType={buttonPromptType}
        type={promptType}
        childComponent={promptChildComponent}
        cancelCallback={promptCancelCallback}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    promptReason: state.promptReason,
    userRole: state.auth.role,
    isPromptShow: state.prompt.isPromptShow,
    promptTitle: state.prompt.promptTitle,
    promptContent: state.prompt.promptContent,
    buttonPromptType: state.prompt.buttonPromptType,
    promptChildComponent: state.prompt.childComponent,
    promptCancelCallback: state.prompt.cancelCallback,
    promptType: state.prompt.promptType,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    apiStart: () => dispatch(actions.apiStart()),
    apiStop: () => dispatch(actions.apiStop()),
    promptActionOk: () => dispatch(actions.promptActionOk()),
    promptActionCancel: () => dispatch(actions.promptActionCancel()),
    onPromptReasonShow: (label, okCallback, cancelCallback) =>
      dispatch(actions.promptReasonShow(label, okCallback, cancelCallback)),
    onPromptReasonHide: () => dispatch(actions.promptReasonHide()),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Quotation)
)
