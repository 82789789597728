import React, { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import * as Router from '../../shared/router'
import { ValidateAuth0API } from '../../api/Auth0/Auth0'
import AppLoader from '../../components/Loader/AppLoader/AppLoader'
import branding from '../../shared/branding'
import toastCenter, { toastMessageType } from '../../shared/toastCenter'
import * as actions from '../../store/actions/index'
import { authStart, checkAuthTimeout } from '../../store/actions/auth'
import socketEvent from '../../socket/socketEvent'
import { getParamLoginEmit, socketEmit } from '../../socket/socket'

const Auth0 = ({ onAuth, onAuthTimeout, loadUserDetail, onLogout }) => {
  const auth0State = useAuth0()
  const [auth0AccessToken, setAuth0AccessToken] = useState(null)

  useEffect(() => {
    const validateAuth0API = new ValidateAuth0API()
    if (auth0AccessToken && auth0State && auth0State.user) {
      onAuth()
      const onNext = (appToken) => {
        if (
          appToken &&
          appToken.client &&
          appToken.accessTokenExpiresAt &&
          appToken.user
        ) {
          localStorage.setItem('clientId', appToken.client.id)
          localStorage.setItem('token', auth0AccessToken)
          if (appToken.viewMode && appToken.viewMode === 'whitelabel') {
            localStorage.setItem('view', 'client')
          }
          localStorage.setItem('expirationDate', appToken.accessTokenExpiresAt)
          localStorage.setItem('userId', appToken.user.id)
          loadUserDetail()
          const expiresIn =
            new Date(appToken.accessTokenExpiresAt).getTime() -
            new Date().getTime()
          onAuthTimeout(expiresIn)
          socketEmit(
            socketEvent.LOGIN,
            getParamLoginEmit(appToken.client.id, auth0AccessToken)
          )
        } else {
          toastCenter.message(
            'Failed!',
            appToken.message,
            toastMessageType.WARNING
          )

          setTimeout(() => {
            onLogout(auth0State.logout)
          }, 2000)
        }
      }
      const onError = () => {
        toastCenter.message(
          'Failed!',
          'Failed to load profile',
          toastMessageType.WARNING
        )
        setTimeout(() => {
          onLogout(auth0State.logout)
        }, 2000)
      }
      const onComplete = () => {}
      validateAuth0API.subscribe(
        auth0AccessToken,
        auth0State.user,
        onNext,
        onComplete,
        onError
      )
    }

    return () => {
      validateAuth0API.unsubscribe()
    }
  }, [
    auth0State,
    auth0AccessToken,
    onAuth,
    onAuthTimeout,
    loadUserDetail,
    onLogout,
  ])

  useEffect(() => {
    if (auth0State && auth0State.user) {
      if (localStorage && localStorage.getItem('token')) {
        setAuth0AccessToken(localStorage.getItem('token'))
      } else {
        auth0State
          .getIdTokenClaims()
          .then((claims) => {
            const token = claims.__raw
            setAuth0AccessToken(token)
          })
          .catch((_) => {
            toastCenter.message(
              'Failed!',
              'Failed to retrieve token',
              toastMessageType.ERROR
            )
          })
        // .getAccessTokenSilently({
        //   authorizationParams: {
        //     audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        //     scope: 'read:users',
        //   },
        // })
        // .then((auth0Token) => {
        //   setAuth0AccessToken(auth0Token)
        // })
        // .catch((err) => {
        //   toastCenter.message('Failed!', err.message, toastMessageType.ERROR)
        // })
      }
    }
  }, [auth0State])

  if (!auth0State || !auth0AccessToken) {
    return <AppLoader branding={branding} loading={true} />
  }

  if (auth0State.isLoading) {
    return <AppLoader branding={branding} loading={true} />
  }

  return auth0State.user ? (
    <AppLoader branding={branding} loading={true} />
  ) : (
    <Redirect to={Router.auth0Login} />
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: () => dispatch(authStart()),
    onAuthTimeout: (expiresIn) => dispatch(checkAuthTimeout(expiresIn)),
    loadUserDetail: () => dispatch(actions.loadUserDetail()),
    onLogout: (cb) => dispatch(actions.logout(cb)),
  }
}

export default connect(null, mapDispatchToProps)(Auth0)
