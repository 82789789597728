import React from 'react'

export const HistoryRow = (props) => {
  const { comment, formattedDate } = props
  return (
    <div className="history-row-container">
      <div className="text-col">
        <div className="title-row">
          <div>{comment.message}</div>
          <div className="time">
            <div className="hour-timezone">{formattedDate[1]}</div>
            <div>{formattedDate[0]}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
