import React, { Fragment, useContext, useState, useEffect } from 'react'
import { isAfter } from 'date-fns'
import { connect } from 'react-redux'

import './Membership.scss'
import MembershipOrder from '../MembershipOrder/MembershipOrder'
import MembershipStorage from '../MembershipStorage/MembershipStorage'
import { Can, AbilityContext } from '../../../context/abilityContext'
import { abilityAction, abilityComponent } from '../../../shared/ability'
import MembershipAllocatedHours from '../MembershipAllocatedHours/MembershipAllocatedHours'
import { defaultDisplayDate } from '../../../shared/dateTimeHelper'
import ActiveOrder from '../ActiveOrder/ActiveOrder'
import { getCurrentView, isCustomerClient } from '../../../shared/utility'

const Membership = ({ user }) => {
  const [isMembershipExpired, setIsMembershipExpired] = useState(false)
  const [textPaymentStatus, setTextPaymentStatus] = useState(
    'Next Payment Due Date'
  )
  const abilityContext = useContext(AbilityContext)

  useEffect(() => {
    if (user && user.membership) {
      const expired =
        user.membership.type === 'free'
          ? false
          : !user.membership.isActive ||
            isAfter(new Date(), new Date(user.membership.dueDate))
          ? true
          : false
      // let statusText = expired ? 'Payment is Due' : 'Next Payment Due Date'
      let statusText = expired
        ? 'Subscription Inactive'
        : 'Next Payment Due Date'
      statusText = isCustomerClient(user) ? 'Monthly Cycle' : statusText
      setIsMembershipExpired(expired)
      setTextPaymentStatus(statusText)
    }
  }, [user])

  const ableToSeeMembershipStatus = (user) => {
    if (user.isClientWhitelabel !== null && user.isSubscriber !== null) {
      return user.isClientWhitelabel || user.isSubscriber
    }
    return false
  }

  if (
    abilityContext.cannot &&
    abilityContext.cannot(
      abilityAction.READ,
      abilityComponent.MEMBERSHIP_CUSTOMER
    )
  ) {
    return null
  }

  if (user === null || user === undefined) {
    return null
  }

  return (
    <Fragment>
      {ableToSeeMembershipStatus(user) && (
        <div className="d-flex flex-column" style={{ marginBottom: '15px' }}>
          <div>
            <span
              className={
                isMembershipExpired
                  ? 'text-16 font-weight-bold text-danger'
                  : 'text-16 font-weight-bold'
              }
            >
              {user && user.membership && user.membership.title}
            </span>
            <span className="ml-2 badge badge-primary text-white">
              {user.membership.paymentTerm}
            </span>
          </div>

          {user.membership.type !== 'free' && (
            <div className="d-flex flex-row">
              <div className="d-flex align-items-center mr-2">
                <span
                  className={
                    isMembershipExpired
                      ? 'dot dot-24 dot-danger'
                      : 'dot dot-24 dot-darkprimary'
                  }
                ></span>
              </div>

              <div className="d-flex flex-grow-1 flex-column">
                <div className="line-height-14">
                  <span className="text-10 text-grey">{textPaymentStatus}</span>
                </div>
                <div className="line-height-16">
                  <span
                    className={
                      isMembershipExpired
                        ? 'font-weight-bold text-14 text-danger'
                        : 'font-weight-bold text-14'
                    }
                  >
                    {user.membership.dueDate
                      ? defaultDisplayDate(new Date(user.membership.dueDate))
                      : null}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {user && getCurrentView(user) === 'customer' && (
        <div
          className="d-flex flex-column membership-status-container"
          style={{ marginTop: '2px' }}
        >
          <Can
            I={abilityAction.READ}
            a={abilityComponent.MANAGE_ALLOCATED_HOURS}
          >
            <MembershipAllocatedHours
              user={user}
              style={{ margin: '0 0 5px 0' }}
            />
          </Can>
          <Can I={abilityAction.READ} a={abilityComponent.MANAGE_ACTIVE_ORDER}>
            <ActiveOrder user={user} style={{ margin: '5px 0' }} />
          </Can>
          <Can I={abilityAction.READ} a={abilityComponent.MANAGE_MEMBERSHIP}>
            <MembershipOrder user={user} style={{ margin: '5px 0' }} />
          </Can>
          <Can I={abilityAction.READ} a={abilityComponent.MANAGE_STORAGE}>
            <MembershipStorage user={user} style={{ margin: '5px 0' }} />
          </Can>
        </div>
      )}
    </Fragment>
  )
}

const mapStateToProps = ({ user }) => {
  return { user }
}

export default connect(mapStateToProps)(Membership)
