import React, { useRef, useEffect, useState } from 'react'
import {
  PdfWebViewer,
  PdfFitMode,
  FileType,
} from '@pdf-tools/four-heights-pdf-web-viewer'
import { pdfToolsAPIKey } from '../../shared/constant'

function PdfToolsDemo() {
  const [pdfBlob, setPdfBlob] = useState(null)
  const viewerContainer = useRef()
  const pdfUrl =
    'https://static.uat.uf.ngine.com.au/unknown-server/2024/6/card-64d483ab12bb2d7068e5f264/1719191454411-sample-icc-version4-1719191454415/sample-icc-version4.pdf?v=1721009013'

  useEffect(() => {
    const fetchPdfBlob = async () => {
      try {
        const response = await fetch(pdfUrl)
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const blob = await response.blob()
        setPdfBlob(blob)
      } catch (error) {
        console.error('Error fetching PDF:', error)
      }
    }

    fetchPdfBlob()
  }, [pdfUrl])

  useEffect(() => {
    const userName = 'John Doe'

    window.pdfjsLib.GlobalWorkerOptions.workerSrc =
      'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.5.207/pdf.worker.js'

    async function handleSaveDocument() {
      const fdfData = await _viewer.save({
        fileType: FileType.Fdf,
      })
      const reader = new FileReader()
      reader.onloadend = function () {
        localStorage.setItem('annotation_pdf_demo', reader.result)
      }
      reader.readAsDataURL(fdfData)

      // _viewer.open({ uri: '/PdfWebViewer.pdf' }, [{ data: fdfData }]);
    }

    async function handleOpenButtonClicked() {}

    const options = {
      general: {
        user: userName,
      },
      viewer: {
        callbacks: {
          onSaveFileButtonClicked: handleSaveDocument,
          onOpenFileButtonClicked: handleOpenButtonClicked,
        },
      },
    }

    const _viewer = new PdfWebViewer(
      viewerContainer.current,
      pdfToolsAPIKey,
      options
    )

    function dataURLToBlob(dataURL) {
      const binaryString = atob(dataURL.split(',')[1])
      const len = binaryString.length
      const bytes = new Uint8Array(len)

      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i)
      }

      return new Blob([bytes], { type: '' })
    }

    _viewer.addEventListener('appLoaded', async () => {
      if (pdfBlob) {
        let annotation_pdf_demo = null
        if (localStorage && localStorage.getItem('annotation_pdf_demo')) {
          annotation_pdf_demo = [
            {
              data: dataURLToBlob(localStorage.getItem('annotation_pdf_demo')),
            },
          ]
        }

        pdfBlob.name = 'PdfWebViewer.pdf'
        _viewer.open({ data: pdfBlob }, annotation_pdf_demo, null, {
          initialFitMode: PdfFitMode.FIT_PAGE,
        })
      }
    })

    return () => {
      _viewer.destroy()
    }
  }, [pdfBlob])

  return (
    <div
      id="viewer-container"
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100vh',
      }}
    >
      <div ref={viewerContainer} style={{ width: '90vw', height: '100vh' }} />
    </div>
  )
}

export default PdfToolsDemo
