import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index'
import { ValidateRfqTokenAPI } from '../../api/User/ValidateRfqToken'
import toastCenter from '../../shared/toastCenter'
import { useQuery } from '../../shared/utility'
import Spinner from '../../components/Loader/Spinner/Spinner'

import BrokenLink from '../Rfq/BrokenLink/BrokenLink'
import clientLogin from '../../shared/clientLogin'

function ImpersonateRfq({ apiStart, apiStop, match }) {
  const query = useQuery()
  const history = useHistory()
  const reviewerToken = query.get('token')

  const [tokenData, setTokenData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  localStorage.setItem('token-type', 'jwt')

  useEffect(() => {
    if (tokenData) {
      localStorage.setItem('token', tokenData.accessToken)
      localStorage.setItem('clientId', clientLogin.PSP)
      localStorage.setItem('expirationDate', tokenData.accessTokenExpiresAt)
      localStorage.setItem('userId', tokenData.userId)
      history.push(`/rfq/${match.params.quotationId}`)
    }
  }, [tokenData, match, history])

  useEffect(() => {
    if (reviewerToken) {
      apiStart()
      const validateRfqTokenAPI = new ValidateRfqTokenAPI()
      const onNext = (response) => {
        apiStop()
        setIsLoading(false)
        if (response.success && response.data) {
          setTokenData(response.data)
        }
      }
      const onError = () => {
        apiStop()
        setIsLoading(false)
        toastCenter.messageServerError()
      }
      const onComplete = () => {}

      validateRfqTokenAPI.subscribe(reviewerToken, onNext, onComplete, onError)

      return () => {
        validateRfqTokenAPI.unsubscribe()
      }
    }
  }, [reviewerToken, apiStart, apiStop])

  return isLoading ? <Spinner /> : <div>{!tokenData && <BrokenLink />}</div>
}

const mapDispatchToProps = (dispatch) => {
  return {
    apiStart: () => dispatch(actions.apiStart()),
    apiStop: () => dispatch(actions.apiStop()),
  }
}

export default connect(null, mapDispatchToProps)(ImpersonateRfq)
