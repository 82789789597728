export const orderStatusOptions = [
  {
    label: 'Awaiting Quote',
    value: 'awaiting-quote',
  },
  {
    label: 'Awaiting Approval',
    value: 'awaiting-approval',
  },
  {
    label: 'Rejected',
    value: 'rejected',
  },
  {
    label: 'Staging',
    value: 'staging',
  },
  {
    label: 'Draft Order Review',
    value: 'before-ready-to-go',
  },
  {
    label: 'Queue',
    value: 'queue',
  },
  {
    label: 'Ready To Start',
    value: 'ready-to-go',
  },
  {
    label: 'In Progress',
    value: 'in-progress',
  },
  {
    label: 'In Review',
    value: 'in-review',
  },
  {
    label: 'On Hold',
    value: 'on-hold',
  },
  {
    label: 'Approved',
    value: 'approved',
  },
  {
    label: 'Completed',
    value: 'completed',
  },
  {
    label: 'Cancelled',
    value: 'cancelled',
  },
  {
    label: 'Not Frozen',
    value: 'not-frozen',
  },
]

export const queueTimeOptions = [
  // {
  //   label: "All Queue Time",
  //   value: '',
  // },
  {
    label: '50% or fewer',
    value: '0:50',
  },
  {
    label: '51% - 75%',
    value: '51:75',
  },
  {
    label: '76% or more',
    value: '76',
  },
]

export const workTimeOptions = [
  // {
  //   label: "All Work Time",
  //   value: '',
  // },
  {
    label: '50% or fewer',
    value: '0:50',
  },
  {
    label: '51% - 75%',
    value: '51:75',
  },
  {
    label: '76% or more',
    value: '76',
  },
]
