import React from 'react'
import PropTypes from 'prop-types'

import './LoaderComponent.scss'

import NgineApiLoader from '../NgineApiLoader/NgineApiLoader'
import Spinner from '../Spinner/Spinner'
import clientLogin from '../../../shared/clientLogin'

function LoaderComponent({ branding = null, loading = false }) {
  const clientId = localStorage.getItem('clientId')
  return (branding && branding.NAME === 'ngine') ||
    (branding && branding.NAME === 'MRM') ||
    (clientId && clientId === clientLogin.ADMIN) ? (
    <div className="loader-component-loader">
      <NgineApiLoader isLoading={loading} />
    </div>
  ) : (
    loading && (
      <div className="loader-component-loader">
        <Spinner />
      </div>
    )
  )
}

LoaderComponent.propType = {
  branding: PropTypes.object,
  loading: PropTypes.bool,
}

export default LoaderComponent
