export default {
  ATTACHMENT: 'attachment',
  ATTACHMENT_VERSION: 'attachment-version',
  AVATAR: 'avatar',
  AVATAR_CUSTOMER: 'avatar-customer',
  LOGO: 'logo',
  FAVICON: 'favicon',
  PLACEHOLDER: 'image-placeholder',
  RFQ_ATTACHMENT: 'rfq-attachment',
}
