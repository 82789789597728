export const quotationStatus = {
  PENDING: 'pending',
  ONQUOTE: 'on-quote',
  INREVIEW: 'in-review',
  CANCELLED: 'cancelled',
  ACCEPTED: 'accepted',
}

export const quotationStatusLabel = {
  pending: 'Pending',
  'on-quote': 'On Quote',
  'in-review': 'In Review',
  cancelled: 'Cancelled',
  accepted: 'Accepted',
}
