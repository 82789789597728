import React from 'react'

import './SearchResult.scss'
import QuotationItem from '../../containers/QuotationBoard/QuotationItem'

const SearchResult = ({
  results = [],
  showResult = false,
  onHideResult = null,
}) => {
  return (
    <div
      className={`quotation-search-result-wrapper ${
        showResult ? 'show-result' : ''
      }`}
    >
      {results.length > 0 && (
        <ul className="container-list">
          {results.map((item, idx) => (
            <QuotationItem key={idx} quotation={item} />
          ))}
        </ul>
      )}
      <div className="backdrop-search-result" onClick={onHideResult}></div>
    </div>
  )
}

export default React.memo(SearchResult)
