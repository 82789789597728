import React from 'react'
import PropTypes from 'prop-types'

import './NgineApiLoader.scss'
import Spinner from '../Spinner/Spinner'

const NgineApiLoader = ({ isLoading = false, className = '' }) => {
  return (
    isLoading && (
      <div className="client-app-loader">
        <Spinner />
      </div>
    )
  )
}

NgineApiLoader.propType = {
  isLoading: PropTypes.bool.isRequired,
}

export default NgineApiLoader
