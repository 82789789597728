import React, {
  useEffect,
  useRef,
  useContext,
  useCallback,
  useState,
} from 'react'
import update from 'immutability-helper'
import * as Sentry from '@sentry/browser'
import { getUnixTime } from 'date-fns'

import './PdfRender.scss'
import { PreviewContext } from '../../context/previewContext'
import { LoaderContext } from '../../context/loaderContext'
import { centeringPDFByHeight } from '../../shared/utils'
import { PdfContext } from '../../context/pdfContext'
import EventBus from '../../shared/eventBus'
import NullL10n from '../../shared/nullL10n'
import { ShapeContext } from '../../context/shapeContext'
import { getWindowScaleList } from '../../shared/zoomHelper'
import { hasQueryParams } from '../../../../shared/utility'
import adobeViewSDKClient from '../../../../shared/adobeViewSDKClient'
import { VersionContext } from '../../context/versionContext'

const PdfRender = () => {
  const [padding, setPadding] = useState(
    `${window.innerHeight / 2}px ${window.innerWidth / 2}px`
  )
  const [eventBus, setEventBus] = useState(null)
  const [l10n, setL10n] = useState(null)

  const containerViewRef = useRef()
  const pdfViewerRef = useRef()

  const { isNewVersionCanvas } = useContext(ShapeContext)
  const { onLoadedPercent } = useContext(LoaderContext)
  const {
    url,
    onImageBoundRectChanged,
    isLoadingFile,
    onIsLoadingFile,
    byteLength,
    imageBoundRect,
    updateZoomDetail,
  } = useContext(PreviewContext)

  const {
    pdfViewerState,
    onPdfViewerState,
    pdfViewerWrappRef,
    onChangeScale,
    marginPage,
    updateTotalPage,
  } = useContext(PdfContext)
  const { selectedVersion } = useContext(VersionContext)

  const updateTotalPageCallback = useCallback(updateTotalPage, [
    updateTotalPage,
  ])

  useEffect(() => {
    setEventBus(new EventBus())
    setL10n(NullL10n)

    /** PDFJS version 2.5.207 */
    window.pdfjsLib.GlobalWorkerOptions.workerSrc =
      'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.5.207/pdf.worker.js'

    const docUrl = `${url}${hasQueryParams(url) ? '&v=' : '?v='}${getUnixTime(
      new Date()
    )}`
    const viewSDKClient = new adobeViewSDKClient()
    viewSDKClient.ready().then(() => {
      // Invoke file preview
      const previewFilePromise = viewSDKClient.previewFile(
        'adobe-viewer',
        {
          embedMode: 'IN_LINE',
          focusOnRendering: true,
          showDownloadPDF: false,
          showZoomControl: false,
          showAnnotationTools: false,
          enableFormFilling: false,
          showPrintPDF: false,
        },
        docUrl,
        selectedVersion?.fileName,
        selectedVersion?.id
      )
      previewFilePromise.then((adobeViewer) => {
        adobeViewer.getAPIs().then((apis) => {
          apis.getPDFMetadata().then((result) => {
            const totalPages = result.numPages
            const maxCount = 2
            let count = 0
            setInterval(() => {
              if (count <= maxCount) {
                for (let index = 1; index <= totalPages; index++) {
                  apis
                    .gotoLocation(index, 0, 0)
                    .catch(() => window.location.reload())
                }
                count++
              }
            }, 1500)
          })
        })
      })
    })
  }, [selectedVersion, url])

  useEffect(() => {
    if (eventBus) {
      let isMounted = true // track whether component is mounted
      const container = containerViewRef.current

      // (Optionally) enable hyperlinks within PDF files.
      const pdfLinkService = new window.pdfjsViewer.PDFLinkService({
        eventBus,
      })

      // (Optionally) enable find controller.
      const pdfFindController = new window.pdfjsViewer.PDFFindController({
        linkService: pdfLinkService,
        eventBus,
      })

      const pdfViewer = new window.pdfjsViewer.PDFViewer({
        container: container,
        linkService: pdfLinkService,
        findController: pdfFindController,
        eventBus,
      })
      pdfLinkService.setViewer(pdfViewer)

      // Loading document.
      const loadingTask = window.pdfjsLib.getDocument({
        url: `${url}${hasQueryParams(url) ? '&v=' : '?v='}${getUnixTime(
          new Date()
        )}`,
        cMapUrl: 'https://static.a.ngine.com.au/annotation/bcmaps/',
        cMapPacked: true,
      })

      loadingTask.onProgress = (event) => {
        if (isMounted && byteLength > 0) {
          if (event) {
            let totalByte = event.loaded

            if (event.total) {
              totalByte = event.total
            } else if (!event.total && byteLength > 0) {
              totalByte = byteLength
            }

            let loaded = (
              (parseFloat(event.loaded) / parseFloat(totalByte)) *
              100
            ).toFixed(0)

            if (typeof onLoadedPercent === 'function') {
              onLoadedPercent(`${loaded}%`)
            }
          } else {
            if (typeof onLoadedPercent === 'function') {
              onLoadedPercent(`100%`)
            }
          }
        }
      }

      onPdfViewerState(pdfViewer)

      loadingTask.promise.then(
        (pdfDocument) => {
          if (isMounted) {
            pdfViewer.setDocument(pdfDocument)
            pdfLinkService.setDocument(pdfDocument, null)
          }
        },
        (exception) => {
          if (isMounted) {
            let loadingErrorMessage

            if (exception instanceof window.pdfjsLib.InvalidPDFException) {
              loadingErrorMessage = l10n.get(
                'invalid_file_error',
                null,
                'Invalid or corrupted PDF file.'
              )
            } else if (
              exception instanceof window.pdfjsLib.MissingPDFException
            ) {
              loadingErrorMessage = l10n.get(
                'missing_file_error',
                null,
                'Missing PDF file.'
              )
            } else if (
              exception instanceof window.pdfjsLib.UnexpectedResponseException
            ) {
              loadingErrorMessage = l10n.get(
                'unexpected_response_error',
                null,
                'Unexpected server response.'
              )
            } else {
              loadingErrorMessage = l10n.get(
                'loading_error',
                null,
                'An error occurred while loading the PDF.'
              )
            }

            return loadingErrorMessage.then((msg) => {
              alert(msg)

              if (process.env.NODE_ENV === 'production') {
                Sentry.captureException(msg)
              }
              // throw exception;
            })
          }
        }
      )

      return () => {
        isMounted = false

        if (loadingTask.destroy && typeof loadingTask.destroy === 'function') {
          loadingTask.destroy()
        }
      }
    }
  }, [url, onLoadedPercent, onPdfViewerState, byteLength, eventBus, l10n])

  /**
   * @deprecated at @version 0.2
   * this for old annotation canvas
   */
  // update margin each pages
  useEffect(() => {
    if (
      !isNewVersionCanvas &&
      pdfViewerRef.current &&
      pdfViewerRef.current.hasChildNodes()
    ) {
      const pagesEl = pdfViewerRef.current.querySelectorAll('.page')
      pagesEl.forEach((page, idx) => {
        if (idx > 0) {
          page.style.marginTop = `${marginPage}px`
        }
      })
    } else {
      const pagesEl = pdfViewerRef.current.querySelectorAll('.page')
      pagesEl.forEach((page) => {
        page.classList.add('pdf-viewer__page')
        page.style.removeProperty('margin-top')
      })
    }
  }, [isNewVersionCanvas, marginPage])

  const centerPositionPDF = useCallback(() => {
    if (
      pdfViewerWrappRef.current &&
      pdfViewerRef.current &&
      pdfViewerRef.current.querySelector('.page')
    ) {
      const pageEl = pdfViewerRef.current.querySelector('.page')
      const heightPage = pageEl.clientHeight

      centeringPDFByHeight(pdfViewerWrappRef.current, heightPage)
    }
  }, [pdfViewerWrappRef])

  const handlePagesloaded = useCallback(
    (_) => {
      onChangeScale(parseFloat(imageBoundRect.scale))

      setTimeout(() => {
        onIsLoadingFile(false)
        centerPositionPDF()

        setTimeout(() => {
          const evt = new Event('annotationFileReady', {
            bubbles: true,
            cancelable: false,
          })
          document.dispatchEvent(evt)
        }, 250)
      }, 300)
    },
    [onChangeScale, imageBoundRect.scale, onIsLoadingFile, centerPositionPDF]
  )

  const handlePagesinit = useCallback(
    function () {
      if (pdfViewerState) {
        pdfViewerState.currentScaleValue = 1

        updateTotalPageCallback(pdfViewerState.pagesCount)

        /**
         * @deprecated at @version 0.2
         * this for old annotation canvas
         */
        // set margin on init
        if (
          !isNewVersionCanvas &&
          pdfViewerRef.current &&
          pdfViewerRef.current.hasChildNodes()
        ) {
          const pagesEl = pdfViewerRef.current.querySelectorAll('.page')
          pagesEl.forEach((page, idx) => {
            if (idx > 0) {
              page.style.marginTop = `${marginPage}px`
            }
          })
        } else {
          const pagesEl = pdfViewerRef.current.querySelectorAll('.page')
          pagesEl.forEach((page) => {
            page.classList.add('pdf-viewer__page')
            page.style.removeProperty('margin-top')
          })
        }

        pdfViewerState.pdfDocument.getPage(1).then((page) => {
          const viewport = page.getViewport({ scale: 1 })
          const naturalWidth = viewport.width
          const naturalHeight = viewport.height

          const resultScale = getWindowScaleList(naturalWidth, naturalHeight)

          const newImageBoundRect = {
            naturalWidth: { $set: naturalWidth },
            naturalHeight: { $set: naturalHeight },
            width: { $set: naturalWidth * resultScale.initialScale },
            height: { $set: naturalHeight * resultScale.initialScale },
            scale: { $set: resultScale.initialScale },
          }

          onImageBoundRectChanged((v) => update(v, newImageBoundRect))

          updateZoomDetail(resultScale)
        })
      }
    },
    [
      isNewVersionCanvas,
      marginPage,
      onImageBoundRectChanged,
      pdfViewerState,
      updateTotalPageCallback,
      updateZoomDetail,
    ]
  )

  const handleResize = useCallback(() => {
    setPadding(`${window.innerHeight / 2}px ${window.innerWidth / 2}px`)
  }, [])

  useEffect(() => {
    if (eventBus) {
      eventBus.on('pagesloaded', handlePagesloaded)
      eventBus.on('pagesinit', handlePagesinit)
      eventBus.on('resize', handleResize)

      return () => {
        eventBus.off('pagesloaded', handlePagesloaded)
        eventBus.off('pagesinit', handlePagesinit)
        eventBus.off('resize', handleResize)
      }
    }
  }, [eventBus, handlePagesinit, handlePagesloaded, handleResize])

  return (
    <div
      className="viewer-container"
      ref={containerViewRef}
      style={{
        padding: padding,
        visibility: isLoadingFile ? 'hidden' : 'visible',
        position: 'relative',
      }}
    >
      <div
        id="viewer"
        className="pdf-viewer"
        ref={pdfViewerRef}
        style={{ visibility: 'hidden' }}
      ></div>
      <div
        id="adobe-viewer-container"
        style={{
          position: 'absolute',
          left: padding ? padding.split(' ')[1] : 0,
          top: padding ? padding.split(' ')[0] : 0,
          width: '100%',
        }}
      >
        <div id="adobe-viewer" className="full-window-div"></div>
      </div>
    </div>
  )
}

export default React.memo(PdfRender)
