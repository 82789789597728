import itemTypes from './itemTypes'
import theme from './theme'

export default [
  {
    label: 'Ready To Start',
    value: itemTypes.NEW_ORDER,
    name: 'new-order',
    color: theme.DARK,
    selectedBackgroundColor: theme.DARKBLUE,
    selectedColor: '#fff',
  },
  {
    label: 'In Progress',
    value: itemTypes.IN_PROGRESS,
    name: 'in-progress',
    color: theme.DARK,
    selectedBackgroundColor: theme.DARKBLUE,
    selectedColor: '#fff',
  },
  {
    label: 'In Review',
    value: itemTypes.IN_REVIEW,
    name: 'in-review',
    color: theme.DARK,
    selectedBackgroundColor: theme.DARKBLUE,
    selectedColor: '#fff',
  },
  {
    label: 'On Hold',
    value: itemTypes.ON_HOLD,
    name: 'onhold',
    color: theme.ORANGE,
    selectedBackgroundColor: theme.ORANGE,
    selectedColor: '#fff',
  },
  {
    label: 'Approved',
    value: itemTypes.APPROVED,
    name: 'approved',
    color: theme.DARKBLUE,
    selectedBackgroundColor: theme.DARKBLUE,
    selectedColor: '#fff',
  },
  {
    label: 'Cancelled',
    value: itemTypes.CANCELLED,
    name: 'cancelled',
    color: theme.RED,
    selectedBackgroundColor: theme.RED,
    selectedColor: '#fff',
  },
]
