export default [
  {
    name: 'pending',
    label: 'Pending',
  },
  {
    name: 'on-quote',
    label: 'On Quote',
  },
  {
    name: 'in-review',
    label: 'In Review',
  },
  {
    name: 'cancelled',
    label: 'Cancelled',
  },
  {
    name: 'accepted',
    label: 'Accepted',
  },
  {
    name: 'create',
    label: 'Create Quotation',
  },
  {
    name: 'delete',
    label: 'Delete Quotation',
  },
  {
    name: 'duplicate',
    label: 'Duplicate Quotation',
  },
  {
    name: 'cancel',
    label: 'Cancel Quotation',
  },
  {
    name: 'restore',
    label: 'Restore Quotation',
  },
  {
    name: 'unaccept',
    label: 'Unaccept Quotation',
  },
]
