import React from 'react'

import './BrokenLink.scss'

const BrokenLink = () => {
  return (
    <div className="broken-link-wrapper">
      <div className="content d-flex flex-column justify-content-center align-items-center">
        <div className="title">404</div>
        <h2 className="text-1">Something is wrong!</h2>
        <p className="text-2">
          Maybe you're trying to open the wrong link or it has expired.
        </p>
      </div>
    </div>
  )
}

export default BrokenLink
