import { Subscriber } from 'rxjs'
import axios from 'axios'

import { baseURLEndpoint } from '../shared/constant'
import clientLogin from '../shared/clientLogin'
import toastCenter from '../shared/toastCenter'
import { resetKanban } from '../shared/utility'

export default class AxiosSubscriber extends Subscriber {
  constructor(
    observer,
    config,
    isWithPrefix = true,
    useRelogin = true,
    isJWTToken = true
  ) {
    super(observer)
    const {
      url,
      method,
      data,
      params,
      headers,
      onDownloadProgress,
      onUploadProgress,
    } = config
    let defaultHeaders = headers
    let defaultOnUploadProgress = onUploadProgress
    let defaultOnDownloadProgress = onDownloadProgress

    if (headers === undefined || headers === null) {
      defaultHeaders = { 'Content-Type': 'application/json' }
    }

    if (!onDownloadProgress) {
      defaultOnDownloadProgress = () => {}
    }

    if (!onUploadProgress) {
      defaultOnUploadProgress = () => {}
    }

    const CancelToken = axios.CancelToken
    this.source = CancelToken.source()

    // XHR complete pointer
    this.completed = false

    let baseUrl = baseURLEndpoint
    const clientId = localStorage.getItem('clientId')
    if (clientId) {
      let urlPrefix = '/'
      if (clientId !== clientLogin.PSP) {
        urlPrefix = clientId === clientLogin.CUSTOMER ? '/c' : '/d'
      }
      baseUrl = isWithPrefix ? baseURLEndpoint + urlPrefix : baseURLEndpoint
    }

    const isOauth =
      localStorage.getItem('token-type') &&
      localStorage.getItem('token-type') === 'oauth'

    if (isJWTToken && !isOauth) {
      const tokenType = localStorage.getItem('token-type')
      defaultHeaders = {
        ...defaultHeaders,
        'xd-token-type': tokenType
          ? tokenType
          : process.env.REACT_APP_TOKEN_TYPE,
        'xd-client': process.env.REACT_APP_WHITELABEL_ID,
      }
    }

    // make axios request on subscription
    const http = axios.create({
      baseURL: baseUrl,
      headers: defaultHeaders,
      cancelToken: this.source.token,
      onUploadProgress: defaultOnUploadProgress,
      onDownloadProgress: defaultOnDownloadProgress,
    })

    let configDelete = { ...params }

    if (data) {
      configDelete = { ...params, data: data }
    }

    let request
    switch (method) {
      case 'post':
        request = http.post(url, data, { params })
        break
      case 'put':
        request = http.put(url, data, { params })
        break
      case 'delete':
        request = http.delete(url, configDelete)
        break
      default:
        request = http.get(url, { params })
    }

    request
      .then((response) => {
        if (response.data.reauth && useRelogin) {
          observer.complete()
          toastCenter.messageServerErrorCustom(response.data)
          resetKanban()
          setTimeout(() => {
            window.location.href = process.env.PUBLIC_URL
          }, 3000)
        } else {
          observer.next(response.data)
          this.completed = true
          observer.complete()
        }
      })
      .catch((error) => {
        this.completed = true
        observer.error(error)
      })
  }

  unsubscribe() {
    super.unsubscribe()

    // cancel XHR
    if (this.completed === false) {
      this.source.cancel('Operation canceled by the user.')
      this.completed = true
    }
  }
}
