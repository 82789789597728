import React from 'react'

export const CommentRow = (props) => {
  const { comment, formattedDate } = props
  return (
    <div className="comment-row-container">
      <div className="avatar-col">
        <img
          className={`${
            comment.senderAvatar === null ? 'empty-avatar' : 'avatar'
          }`}
          src={comment.senderAvatar}
          alt="avatar"
        />
      </div>

      <div className="text-col">
        <div className="title-row">
          <div>{comment.senderName}</div>
          <div className="time">
            <div className="hour-timezone">{formattedDate[1]}</div>
            <div>{formattedDate[0]}</div>
          </div>
        </div>
        <div className="content-row">{comment.message}</div>
      </div>
    </div>
  )
}
