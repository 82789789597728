import React, { useRef, useEffect } from 'react'
import SkeletonLine from '../../components/Skeleton/SkeletonLine'
import { Info } from 'react-feather'
import tippy from 'tippy.js'

export const withLabel = (WrappedComponent) => {
  return React.forwardRef((props, ref) => {
    const withLabel = props.withLabel !== undefined ? props.withLabel : true
    const withPopup = props.withPopup !== undefined ? props.withPopup : false

    const containerRef = useRef()

    useEffect(() => {
      if (withPopup) {
        tippy(containerRef.current, {
          content: 'This information is only accessible for you and your team',
          animation: 'shift-away',
          zIndex: 1020,
          appendTo: containerRef.current,
          placement: 'right',
        })
      }
    }, [withPopup])
    return (
      <div
        className={`form-group ${
          props.formClassName ? props.formClassName : ''
        }`}
      >
        {props.isLoading ? (
          <SkeletonLine style={{ ...props.style, height: '32px' }} />
        ) : withLabel ? (
          <>
            {props.label && (
              <>
                <label htmlFor="title">
                  {props.label}&nbsp;
                  {props.isRequired && <span className="text-danger">*</span>}
                </label>
                {withPopup && (
                  <span ref={containerRef}>
                    <Info size={12} className="info-popup-withlabel" />
                  </span>
                )}
              </>
            )}
            <WrappedComponent {...props} ref={ref} />
          </>
        ) : (
          <WrappedComponent {...props} ref={ref} />
        )}
      </div>
    )
  })
}
