import React, { useState, useEffect } from 'react'

import { RfqCommentsAPI } from '../../../api/Rfq/GetRfqComments'
import { AddRfqCommentsAPI } from '../../../api/Rfq/AddtRfqComments'

import toastCenter from '../../../shared/toastCenter'
import FormHandler from '../../../factory/FormHandler'

// import CommentTypeDropdown from './CommentTypeDropdown'
// import Comments from './Comments'
// import SectionTextEditor from '../SectionTextEditor/SectionTextEditor'
import { urlSearchParams } from '../../DesignOrderBoard/utils/helper'
import { CommentRow } from './CommentRow'
import { HistoryRow } from './HistoryRow'

const options = [
  {
    label: 'Notes & History',
    value: 'COMMENT_HISTORY',
  },
  {
    label: 'Notes',
    value: 'COMMENT',
  },
  {
    label: 'History',
    value: 'HISTORY',
  },
]

const CommentAndVersion = ({
  quotationId,
  rfq,
  user,
  psp,
  type,
  changeActiveVersion,
  activeVersion,
  refetch,
  apiStart,
  apiStop,
}) => {
  const [activeTab, setActiveTab] = useState('activity')
  const [comments, setComments] = useState([])
  const [selectedTypeComment] = useState(options[1])
  const [versions, setVersions] = useState([])
  const [inputComment, setInputComment] = useState('')
  // todo add
  // const handleSelectedTypeComment = (comments, type) => {
  //   if(type === "COMMENT" || type === "HISTORY"){

  //   } else {

  //   }
  // }

  const handleFormatDate = (date) => {
    const d = new Date(date).toString()
    const result = [
      `${d.slice(4, 7)} ${d.slice(8, 10)}, ${d.slice(11, 15)}`,
      `${d.slice(16, 24)} (${d.slice(25, 31)}:${d.slice(31, 33)})`,
    ]
    return result
  }

  const handleGetRftComments = () => {
    let queryString
    if (selectedTypeComment.value === options[1].value) {
      queryString = urlSearchParams({
        // type: 'comment',
        limit: 20,
      })
    } else if (selectedTypeComment.value === options[2].value) {
      queryString = urlSearchParams({
        type: 'history',
        limit: 20,
      })
    } else {
      queryString = urlSearchParams({
        limit: 20,
      })
    }

    let rfqCommentsAPI = new RfqCommentsAPI()

    const onNext = (response) => {
      if (response.success) {
        setComments(response.data)
      }
    }
    const onComplete = () => {}
    const onError = () => {}

    rfqCommentsAPI.subscribe(
      quotationId,
      onNext,
      onComplete,
      onError,
      queryString
    )
  }

  useEffect(() => {
    handleGetRftComments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTypeComment, refetch])

  useEffect(() => {
    if (rfq && rfq.versions && rfq.versions.length > 0) {
      setVersions(rfq.versions)
    }
  }, [rfq])

  const handleTabChange = (tab) => {
    setActiveTab(tab)
  }

  // const handleChangeTypeComment = (value) => {
  //   setSelectedTypeComment(value)
  // }

  // todo archive sendMessage function with reply feature
  // const handleSendMessage = (
  //   isReplyMsg,
  //   messageObj,
  //   replyId,
  //   mentionedLists,
  //   commentPlainText
  // ) => {
  //   let data = new FormHandler()
  //   data.append('description', messageObj)
  //   data.append('mentions', mentionedLists)
  //   data.append('descriptionRaw', commentPlainText)

  //   const onNext = (response) => {
  //     if (response.success) {
  //       if (localStorage && localStorage.getItem('temp_uploaded_files')) {
  //         localStorage.removeItem('temp_uploaded_files')
  //       }
  //     } else {
  //       toastCenter.message(
  //         'Failed!',
  //         response.message,
  //         toastMessageType.WARNING
  //       )
  //     }
  //   }
  //   const onComplete = () => {}
  //   const onError = () => {
  //     toastCenter.messageServerError()
  //   }

  //   if (isReplyMsg) {
  //     data.append('reply', replyId)
  //     const orderDetailReplyCommentApi = new AddRfqCommentsAPI()
  //     orderDetailReplyCommentApi.subscribe(
  //       '1',
  //       data.all(),
  //       onNext,
  //       onComplete,
  //       onError
  //     )
  //   } else {
  //     const orderDetailAddCommentApi = new AddRfqCommentsAPI()
  //     orderDetailAddCommentApi.subscribe(
  //       '1',
  //       data.all(),
  //       onNext,
  //       onComplete,
  //       onError
  //     )
  //   }
  // }

  const handleSendMessage = (pspId, quotationPspId, userId, type, message) => {
    let data = new FormHandler()
    data.append('pspId', pspId)
    data.append('senderId', userId)
    data.append('type', 'comment')
    data.append('senderUserType', type)
    data.append('message', message)

    const onNext = (response) => {
      if (response.success) {
        handleGetRftComments()
        setInputComment('')
      }
    }
    const onComplete = () => {}
    const onError = () => {
      toastCenter.messageServerError()
    }
    const orderDetailReplyCommentApi = new AddRfqCommentsAPI()
    orderDetailReplyCommentApi.subscribe(
      quotationPspId,
      data.all(),
      onNext,
      onComplete,
      onError
    )
  }

  return (
    <div className="container-fluid h-70vh">
      <div className="bg-white comments-and-version-section">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === 'activity' ? 'active' : ''}`}
              onClick={() => handleTabChange('activity')}
            >
              Activity
            </button>
          </li>
          {type !== 'psp' && (
            <li className="nav-item">
              <button
                className={`nav-link ${
                  activeTab === 'versions' ? 'active' : ''
                }`}
                onClick={() => handleTabChange('versions')}
              >
                Versions
              </button>
            </li>
          )}
        </ul>
        <div
          className={`tab-content d-flex flex-column${
            comments && Array.isArray(comments) && comments.length === 0
              ? ' align-items-center'
              : ''
          }`}
        >
          {activeTab === 'activity' && (
            <>
              <div className="px-4 h-100 w-100">
                <div
                  className="py-2"
                  style={{ maxWidth: 150, marginLeft: '-10px' }}
                >
                  {/* <CommentTypeDropdown
                    options={options}
                    selectedValue={selectedTypeComment}
                    onChange={handleChangeTypeComment}
                  /> */}
                </div>
                <div className="no-history">
                  {comments &&
                  Array.isArray(comments) &&
                  comments.length === 0 ? (
                    <div>You don't have any activity yet...</div>
                  ) : (
                    <div className="scrollable-body">
                      <ul className="list-unstyled w-100 ">
                        {comments.map((comment, idx) => (
                          <div key={idx}>
                            {comment.type === 'comment' ? (
                              <CommentRow
                                comment={comment}
                                formattedDate={handleFormatDate(
                                  comment.updatedAt
                                )}
                              />
                            ) : (
                              <HistoryRow
                                comment={comment}
                                formattedDate={handleFormatDate(
                                  comment.updatedAt
                                )}
                              />
                            )}
                            {/* // TODO enable when need more feature than basic text */}
                            {/* <Comments
                              userComment={comment}
                              key={idx}
                              user={user}
                            /> */}
                          </div>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              <div className="sticky-comment-input">
                <div className="comment-input-container container d-flex flex-column justify-content-center w-100 bg-white">
                  {/* // todo change when need more feature than enter basic string */}
                  {/* <SectionTextEditor
                    ref={commentWrapperRef}
                    quotationId={quotationId} //rfqId
                    user={user}
                    psp={psp}
                    type={type}
                    orderId={1}
                    onSendMessage={handleSendMessage}
                    isStickyActive={false}
                    isDisabled={false}
                  /> */}
                  <div className="rfq-form">
                    <div className="avatar-col">
                      <img
                        className={`${
                          user.avatar === null ? 'empty-avatar' : 'avatar'
                        }`}
                        src={user.avatar}
                        alt="user avatar"
                      />
                    </div>
                    <div className="form-col">
                      <textarea
                        rows={3}
                        className="input"
                        type="text"
                        value={inputComment}
                        placeholder="Add comment"
                        onChange={(e) => setInputComment(e.target.value)}
                      />
                      <div className="button-row">
                        <button
                          type="submit"
                          onClick={() => {
                            handleSendMessage(
                              psp.id,
                              quotationId,
                              user._id,
                              type,
                              inputComment
                            )
                          }}
                          className={`btn ${
                            !inputComment ? ' btn-secondary' : 'btn-darkprimary'
                          }`}
                          disabled={!inputComment}
                        >
                          Submit
                        </button>
                        <button
                          type="button"
                          onClick={() => setInputComment('')}
                          className="btn btn-secondary mr-3"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {activeTab === 'versions' && type !== 'psp' && (
            <div className="tab-pane fade show active w-100">
              <div className="p-4">
                {versions.map((version, idx) => (
                  <div
                    key={idx}
                    className={`version-row cursor-pointer ${
                      version.version === activeVersion.version ? 'active' : ''
                    } d-flex flex-row justify-content-between`}
                    onClick={() => changeActiveVersion(version)}
                  >
                    <span>{`Version ${version.version} ${
                      idx === 0 ? '(Current)' : ''
                    }`}</span>
                    <span className="text-grey">
                      {handleFormatDate(version.createdAt)}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CommentAndVersion
