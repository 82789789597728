export default [
  {
    name: 'published',
    label: 'Ready To Start',
    status: 'Publish',
  },
  {
    name: 'in-progress',
    label: 'In Progress',
    status: 'In Progress',
  },
  {
    name: 'in-review',
    label: 'In Review',
    status: 'In Review',
  },
  {
    name: 'ready-to-go',
    label: 'Ready To Start',
    status: 'Ready To Start',
  },
  {
    name: 'on-hold',
    label: 'Hold Order',
    status: 'Hold from {{orderProgress}}',
  },
  {
    name: 'queue',
    label: 'Queue Order',
    status: 'Queued',
  },
  {
    name: 'freeze',
    label: 'Frozen Order',
    status: 'Frozen',
  },
  {
    name: 'cancelled',
    label: 'Cancel Order',
    status: 'Cancelled from {{orderProgress}}',
  },
  {
    name: 'draft',
    label: 'Revert to Draft',
    status: 'Draft',
  },
  {
    name: 'delete',
    label: 'Delete Order',
    status: 'Delete',
  },
  {
    name: 'duplicate',
    label: 'Duplicate Order',
    status: 'Duplicate',
  },
  {
    name: 'resume',
    label: 'Resume Order',
    status: 'Resume',
  },
  {
    name: 'restore',
    label: 'Restore Order',
    status: 'Restore',
  },
  {
    name: 'staging',
    label: 'Revert to Staging',
    status: 'Staging',
  },
  {
    name: 'before-ready-to-go',
    label: 'Order Review',
    status: 'Draft',
  },
  {
    name: 'awaiting-quote',
    label: 'Awaiting Quote',
    status: 'Awaiting Quote',
  },
  {
    name: 'awaiting-approval',
    label: 'Awaiting Approval',
    status: 'Awaiting Approval',
  },
  {
    name: 'rejected',
    label: 'Rejected',
    status: 'Rejected',
  },
  {
    name: 'approved',
    label: 'Approved',
    status: 'Approved',
  },
  {
    name: 'gr-revision',
    label: 'GR Revision',
    status: 'GR Revision',
  },
  {
    name: 'archive',
    label: 'Archive Order',
    status: 'Archive',
  },
]
