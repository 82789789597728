import React, { useEffect, useState, useLayoutEffect, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
// import { useAuth0 } from "@auth0/auth0-react";

import './scss/styles.scss'
import './scss/stylesngine.scss'
import './App.scss'

import * as actions from './store/actions/index'
import Router from './Router'
import * as RouterPath from './shared/router'
import AppLoader from './components/Loader/AppLoader/AppLoader'
import ApiLoader from './components/Loader/ApiLoader/ApiLoader'
import UserWelcome from './components/UserWelcome/UserWelcome'
import CloseButtonToast from './components/CloseButtonToast/CloseButtonToast'
import userRole from './shared/userRole'
import { socketListen, socketRemoveEventListener } from './socket/socket'
import socketEvent from './socket/socketEvent'
import toastCenter from './shared/toastCenter'
import branding from './shared/branding'
import { Theme } from './Theme'
import BackDrop from './components/BackDrop/BackDrop'
import { toggleView, getCurrentView, getBrowserDetail } from './shared/utility'
// import Auth0 from './containers/Auth/Auth0'
import { Auth0ProviderWithNavigate } from './containers/Auth/Auth0-provider-with-navigate'
import { CancelScheduleWorkingTimeStopperAPI } from './api/DesignOrder/CancelScheduleWorkingTimeStopper'

export const App = ({
  onTryAutoSignout,
  onAccessPath,
  token,
  user,
  location,
  authLoading,
  apiLoading,
  history,
  pushCountNotification,
  pushMessageNotification,
  loadUserDetail,
  notification,
}) => {
  const [userWelcomeShow, setUserWelcomeShow] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(null)

  const onVisibilityChange = useCallback(() => {
    if (document.visibilityState === 'visible') {
      if (
        user &&
        user.role &&
        [
          userRole.MANAGER,
          userRole.CREATIVE_MANAGER,
          userRole.PRODUCTION_MANAGER,
          userRole.OPERATOR,
        ].includes(user.role)
      ) {
        const cancelScheduleWorkingTimeStopperAPI = new CancelScheduleWorkingTimeStopperAPI()
        cancelScheduleWorkingTimeStopperAPI.subscribe(
          (_) => {},
          () => {},
          (_) => {}
        )
      }
    }
  }, [user])

  useLayoutEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange)

    return () =>
      document.removeEventListener('visibilitychange', onVisibilityChange)
  }, [onVisibilityChange])

  useEffect(() => {
    const isAttachmentDetailPage = /^\/orders\/detail\/[^/]+\/attachments\/[^/]+$/.test(
      location.pathname
    )

    const socketFnReloadProfile = () => {
      loadUserDetail()
    }

    const socketFnReloadSidebar = () => {
      loadUserDetail()
    }

    if (!isAttachmentDetailPage) {
      socketListen(socketEvent.RELOAD_PROFILE, socketFnReloadProfile)
      socketListen(socketEvent.RELOAD_SIDEBAR, socketFnReloadSidebar)
    }

    return () => {
      socketRemoveEventListener(
        socketEvent.RELOAD_PROFILE,
        socketFnReloadProfile
      )
      socketRemoveEventListener(
        socketEvent.RELOAD_SIDEBAR,
        socketFnReloadSidebar
      )
    }
  }, [loadUserDetail, location])

  useEffect(() => {
    const socketFnShowNotification = (data) => {
      if (typeof data === 'object') {
        if (typeof pushMessageNotification === 'function') {
          pushMessageNotification(data)
        }
      }
    }
    socketListen(socketEvent.SHOW_NOTIFICATION, socketFnShowNotification)

    return () => {
      socketRemoveEventListener(
        socketEvent.SHOW_NOTIFICATION,
        socketFnShowNotification
      )
    }
  }, [pushMessageNotification])

  useEffect(() => {
    const socketFnForceLogout = (data) => {
      if (typeof data === 'boolean') {
        history.push(RouterPath.signout)
      }
    }
    socketListen(socketEvent.FORCE_LOGOUT, socketFnForceLogout)

    const browser = getBrowserDetail()
    if (!browser.agent) {
      history.push(RouterPath.notSupport)
    }

    return () => {
      socketRemoveEventListener(socketEvent.FORCE_LOGOUT, socketFnForceLogout)
    }
  }, [history])

  useEffect(() => {
    const isAttachmentDetailPage = /^\/orders\/detail\/[^/]+\/attachments\/[^/]+$/.test(
      location.pathname
    )

    const socketFn = (data) => {
      if (typeof data === 'number') {
        if (typeof pushCountNotification === 'function') {
          pushCountNotification(data)
        }
      }
    }

    if (!isAttachmentDetailPage) {
      socketListen(socketEvent.FETCH_MESSAGES, socketFn)
    }

    return () => {
      socketRemoveEventListener(socketEvent.FETCH_MESSAGES, socketFn)
    }
  }, [pushCountNotification, location])

  useEffect(() => {
    if (location.pathname === RouterPath.impersonateCustomer) {
      setIsAuthenticated(false)
    } else {
      setIsAuthenticated(token !== null)
    }
  }, [token, location])

  useEffect(() => {
    onTryAutoSignout()
  }, [onTryAutoSignout])

  useEffect(() => {
    if (notification && notification.content !== null) {
      toastCenter.pushNotification(
        'New Message',
        notification.content.message,
        notification.content.type
      )
    }
  }, [notification])

  useEffect(() => {
    if (
      location.pathname !== RouterPath.home &&
      location.pathname !== RouterPath.signin &&
      location.pathname !== RouterPath.auth0Login &&
      location.pathname !== RouterPath.signinAdmin &&
      location.pathname !== RouterPath.auth0Validate &&
      !isAuthenticated
    ) {
      onAccessPath(location.pathname)
    }
  }, [onAccessPath, location, isAuthenticated])

  useEffect(() => {
    if (
      user &&
      location.pathname !== RouterPath.manageProfile &&
      location.pathname !== RouterPath.signin &&
      location.pathname !== RouterPath.signinAdmin &&
      location.pathname !== RouterPath.forgotPassword &&
      location.pathname !== RouterPath.verifyEmail
    ) {
      if (
        user.role === userRole.OWNER &&
        user.isProfileComplete !== undefined &&
        user.isProfileComplete !== null &&
        user.isProfileComplete === true &&
        user.isClientProfileComplete !== undefined &&
        user.isClientProfileComplete !== null &&
        user.isClientProfileComplete === false &&
        getCurrentView(user) !== 'client'
      ) {
        toggleView(user, 'client')
      }

      if (
        user.isProfileComplete !== undefined &&
        user.isProfileComplete !== null &&
        user.isProfileComplete === false
      ) {
        setUserWelcomeShow(true)
      } else if (user.isProfileComplete === true) {
        setUserWelcomeShow(false)
      }
    } else {
      setUserWelcomeShow(false)
    }
  }, [location, user])

  let classPage = ''

  if (location) {
    classPage = `page-${location.pathname.replace(/\//g, '')}`
    if (location.pathname === '/') {
      classPage = `page-design-order-board`
    } else if (location.pathname.indexOf('/orders/detail/') >= 0) {
      classPage = `page-design-order-board page-${location.pathname.replace(
        /\//g,
        ''
      )}`
    }
  }

  return (
    <Auth0ProviderWithNavigate>
      <div className={`App-Design-Desk ${classPage}`}>
        <Helmet>
          <title>{branding.NAME}</title>
          <link rel="canonical" href={branding.KANBAN} />
        </Helmet>
        <Theme isAuthenticated={isAuthenticated} />
        <AppLoader branding={branding} loading={authLoading} />
        <ApiLoader branding={branding} loading={apiLoading} />
        <UserWelcome show={userWelcomeShow} onHide={() => false} />
        <ToastContainer
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          hideProgressBar={true}
          autoClose={6000}
          closeButton={<CloseButtonToast />}
        />
        <Router isAuthenticated={isAuthenticated} isLoading={authLoading} />
        <BackDrop />
      </div>
    </Auth0ProviderWithNavigate>
  )
}

App.propTypes = {
  onTryAutoSignout: PropTypes.func,
  onAccessPath: PropTypes.func,
  token: PropTypes.any,
  user: PropTypes.object,
  location: PropTypes.object,
  authLoading: PropTypes.bool,
  apiLoading: PropTypes.bool,
  history: PropTypes.any,
  pushCountNotification: PropTypes.func,
  pushMessageNotification: PropTypes.func,
  loadUserDetail: PropTypes.func,
  notification: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    token: state.auth.token,
    authLoading: state.auth.loading,
    apiLoading: state.api.loading,
    notification: state.notification,
    backdrop: state.backdrop,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignout: () => dispatch(actions.authCheckState()),
    onAccessPath: (path) => dispatch(actions.routeAccessPath(path)),
    pushCountNotification: (count) =>
      dispatch(actions.pushCountNotification(count)),
    pushMessageNotification: (data) =>
      dispatch(actions.pushMessageNotification(data)),
    loadUserDetail: () => dispatch(actions.loadUserDetail()),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
