import { Subject } from 'rxjs'

const subject = new Subject()
export const quotationItemService = {
  emitReload: (val) => subject.next(val),
  reload: () => subject.asObservable(),
}

const emptySubject = new Subject()
export const emptyQuotationItemBubbleService = {
  emitReload: (val) => emptySubject.next(val),
  reload: () => emptySubject.asObservable(),
}
