import React, { useRef } from 'react'
import { connect } from 'react-redux'
import * as Icon from 'react-feather'
import PropTypes from 'prop-types'
import './Attachment.scss'

import * as actions from '../../../store/actions/index'
import { doUploadMedia } from '../../../shared/fileHelper'
import mediaType from '../../../shared/mediaType'
import { buttonPromptType } from '../../../shared/promptType'
import toastCenter, { toastMessageType } from '../../../shared/toastCenter'
import FormHandler from '../../../factory/FormHandler'

import { DeleteAttachmentAPI } from '../../../api/Rfq/DeleteAttachment'
import { AddAttachmentAPI } from '../../../api/Rfq/AddAttachment'

const Attachment = ({
  apiStart,
  apiStop,
  quotationPspId,
  userRole,
  roles,
  attachments,
  downloadAttachment,
  setRefetch,
  activeVersion,
  onPromptShow,
}) => {
  const refAttachment = useRef()

  const handleAddAttachmentClick = () => {
    refAttachment.current.click()
  }

  const handleAddAttachmentFile = (e) => {
    doUploadAttachment(Array.from(e.target.files))
  }

  const doUploadAttachment = (attachments) => {
    setRefetch((prevState) => !prevState)
    doUploadMedia(quotationPspId, attachments, mediaType.RFQ_ATTACHMENT)
      .then((res) => {
        const { failed, responseCollection } = res

        const addAttachmentAPI = new AddAttachmentAPI()

        const formData = new FormHandler()
        formData.append('quotationPspAttachmentId', responseCollection[0].id)

        const onNext = (response) => {
          if (response.success) {
            localStorage.setItem(
              'temp_uploaded_files',
              JSON.stringify(responseCollection)
            )
            setRefetch((prevState) => !prevState)
          } else {
            toastCenter.message(
              'Failed while uploading attachment',
              failed,
              toastMessageType.WARNING
            )
          }
        }

        addAttachmentAPI.subscribe(
          quotationPspId,
          formData.all(),
          onNext,
          () => {},
          () => {
            toastCenter.messageServerError()
          }
        )
      })
      .catch((err) => {
        toastCenter.messageServerError()
      })
  }

  const handleRemoveAttachment = (attachment) => {
    onPromptShow(
      'Confirm Deletion',
      `Are you sure you want to delete this file?.`,
      () => {
        removeAttachment(attachment)
      },
      buttonPromptType.CONTINUECANCEL
    )
  }

  const removeAttachment = (attachment) => {
    const deleteAttachmentAPI = new DeleteAttachmentAPI()
    setRefetch((prevState) => !prevState)

    const onNext = (response) => {
      if (response.success) {
        toastCenter.message(
          'Attachment Deleted',
          'The attachment successfully deleted.'
        )
      } else {
        toastCenter.messageServerErrorCustom(response)
      }
    }

    const onComplete = () => {
      setRefetch((prevState) => !prevState)
    }

    const onError = () => {
      toastCenter.messageServerError()
    }

    deleteAttachmentAPI.subscribe(
      quotationPspId,
      attachment._id,
      onNext,
      onComplete,
      onError
    )
  }

  return (
    <div className="attachment">
      {userRole === roles[0] && (
        <div
          onDragOver={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          onDrop={(e) => {
            e.preventDefault()
            e.stopPropagation()
            const files = e.dataTransfer.files
            if (files && files.length > 0) {
              doUploadAttachment(Array.from(files))
            }
          }}
          className="dropzone"
        >
          <input
            ref={refAttachment}
            type="file"
            className="input"
            onChange={handleAddAttachmentFile}
          />
          <div htmlFor="fileInput">
            <div className="label-file">
              <div className="choose-files" onClick={handleAddAttachmentClick}>
                Choose files
              </div>{' '}
              or drag and drop file here
            </div>
            <div className="supported-files">
              Supported files: <br />
              .csv, .doc, .docx, .jpeg, .jpg, .pdf, .txt, .xls, .xlsx, .zip{' '}
              <br />
            </div>
            <div className="max-file-size">Maximum File Size 500 MB</div>
          </div>
        </div>
      )}
      {userRole === roles[1] && attachments.length === 0 && (
        <div className="no-attachment">There is no file attachment here.</div>
      )}
      {attachments.map((attachment, idx) => (
        <div
          key={idx}
          className={`d-flex flex-row w-100 pb-2 mt-3 align-items-center ${
            attachments.length - 1 !== idx && 'border-bottom'
          }`}
        >
          <img
            src={attachment.thumbnail}
            alt=""
            height={40}
            width={40}
            className="pr-2"
          />
          <div className="pr-2 file">
            <div>{attachment.fileName}</div>
            <div className="text-grey">{attachment.fileType}</div>
          </div>
          <div className="ml-auto">
            <Icon.Download
              width={18}
              height={18}
              className="cursor-pointer"
              onClick={() => downloadAttachment(attachment)}
            />
            {userRole === roles[0] && (
              <Icon.Trash2
                onClick={() => handleRemoveAttachment(attachment)}
                color="red"
                width={18}
                height={18}
                className="ml-2 cursor-pointer"
              />
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

Attachment.propTypes = {
  attachments: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired,
  downloadAttachment: PropTypes.func.isRequired,
}

const mapStateToProps = ({ user }) => {
  return { user }
}

const mapDispatchToProps = (dispatch) => {
  return {
    apiStart: () => dispatch(actions.apiStart()),
    apiStop: () => dispatch(actions.apiStop()),
    onPromptShow: (title, content, callback, btnType) =>
      dispatch(actions.promptShow(title, content, callback, btnType)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Attachment)
