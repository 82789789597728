import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import autosize from 'autosize'

import './Textarea.scss'
import { withLabel } from '../../../hoc/withLabel/withLabel'
import { withEditable } from '../../../hoc/withEditable/withEditable'

const Textarea = React.forwardRef(
  (
    {
      onChange,
      isRequired = false,
      readOnly = false,
      isEmpty = false,
      value = null,
      onFocus = null,
      maxLength = null,
      placeholder = '',
      row = '3',
      className = '',
    },
    ref
  ) => {
    const textareaRef = useRef(null)

    useEffect(() => {
      const textareaEL = textareaRef.current
      autosize(textareaEL)

      return () => {
        autosize.destroy(textareaEL)
      }
    }, [])

    // useEffect(() => {
    //   const textareaEL = textareaRef.current
    //   autosize.update(textareaEL)
    // }, [value])
    return (
      <div className="textarea-wrapper textbox-form-container">
        <textarea
          ref={textareaRef}
          onFocus={onFocus}
          className={`form-control ${className}`}
          rows={row}
          maxLength={maxLength}
          value={value}
          readOnly={readOnly}
          onChange={onChange}
          placeholder={placeholder}
        />
        {isRequired && isEmpty && (
          <div className={`input-validation-msg ${isEmpty && 'show'}`}>
            <span className="font-weight-bold">Required!</span>
          </div>
        )}
        {value.length > 3999 && (
          <div className={`input-validation-msg  show`}>
            <span className="font-weight-bold">
              Max. 4000 character has been reached!
            </span>
          </div>
        )}
      </div>
    )
  }
)

Textarea.propTypes = {
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  value: PropTypes.any,
  maxLength: PropTypes.any,
  isRequired: PropTypes.bool,
  readOnly: PropTypes.bool,
  isEmpty: PropTypes.bool,
  placeholder: PropTypes.string,
  row: PropTypes.string,
  className: PropTypes.string,
}

export default withEditable(withLabel(Textarea))
