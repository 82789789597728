import React from 'react'

function Logo({ width }) {
  return (
    <img
      src={`${process.env.PUBLIC_URL}/Logo.png`}
      alt="Logo"
      style={{
        width: 'auto',
        height: 'auto',
        maxWidth: 120,
        maxHeight: '100%',
      }}
    />
  )
}

export default Logo
