import React, { useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { quotationStatus, quotationStatusLabel } from '../../shared/quotation'
import Avatar from '../../components/Avatar/Avatar'
import { truncateText } from '../../shared/utility'
import { defaultDisplayDate } from '../../shared/dateTimeHelper'
import { socketListen, socketRemoveEventListener } from '../../socket/socket'
import socketEvent from '../../socket/socketEvent'
import {
  quotationItemService,
  emptyQuotationItemBubbleService,
} from '../../services/quotationService/quotationItemService'
import update from 'immutability-helper'

import './QuotationItem.scss'
import { QuotationItemAPI } from '../../api/Quotation/QuotationItem'

function QuotationItem({ quotation }) {
  const cardRef = useRef(null)
  const [quotationDetail, setQuotationDetail] = useState(null)
  const [navigateLinkCard, setNavigateLinkCard] = useState('')
  const [displayStatus, setDisplayStatus] = useState('')

  useEffect(() => {
    const quotationItemAPI = new QuotationItemAPI()

    const reloadQuotationItem = (quotationId) => {
      if (quotation && quotation.id === quotationId) {
        quotationItemService.emitReload(quotationId)
      }
    }

    const subscription = quotationItemService
      .reload()
      .subscribe((quotationId) => {
        if (quotation && quotation.id === quotationId) {
          const onNext = (res) => {
            if (res.success && res.data) {
              setQuotationDetail(res.data)
            }
          }
          const onComplete = () => {}
          const onError = () => {}
          quotationItemAPI.subscribe(quotationId, onNext, onComplete, onError)
        }
      })

    const emptySubscription = emptyQuotationItemBubbleService
      .reload()
      .subscribe((quotationId) => {
        if (quotation && quotation.id === quotationId) {
          setQuotationDetail((x) =>
            update(x, {
              quotationNewMessages: { $set: 0 },
            })
          )
        }
      })

    socketListen(socketEvent.RELOAD_QUOTATION_ITEM, reloadQuotationItem)

    return () => {
      socketRemoveEventListener(
        socketEvent.RELOAD_QUOTATION_ITEM,
        reloadQuotationItem
      )
      subscription.unsubscribe()
      emptySubscription.unsubscribe()
      quotationItemAPI.unsubscribe()
    }
  }, [quotation])

  useEffect(() => {
    if (quotation) {
      setQuotationDetail(quotation)
      setNavigateLinkCard(`/quotation/detail/${quotation.id}`)
      setDisplayStatus(quotation.status)
    }
  }, [quotation])

  return (
    <>
      {quotationDetail && (
        <div
          className={`quotation-item status-${quotationDetail.status}`}
          ref={cardRef}
        >
          {quotationDetail.quotationNewMessages &&
          quotationDetail.quotationNewMessages > 0 ? (
            <div className="bubble-message">
              {quotationDetail.quotationNewMessages > 9
                ? `9+`
                : quotationDetail.quotationNewMessages}
            </div>
          ) : null}
          <NavLink to={navigateLinkCard} className="quotation-link">
            <div className={`quotation-item-container`}>
              <div className="row">
                <div className="col-md-12">
                  {quotationDetail.status !== quotationStatus.CANCELLED &&
                    quotationDetail.status !== quotationStatus.ACCEPTED && (
                      <div className="title-status-card line-height-12">
                        <div className="title-status d-flex flex-row align-items-center font-weight-bold flex-grow-1 line-height-12">
                          <span className={`dot dot-12 mr-3`}></span>
                          <span
                            className={`font-weight-bold text-14 line-height-14 status-label`}
                          >
                            {displayStatus}
                          </span>
                        </div>
                      </div>
                    )}
                  {quotationDetail.status === quotationStatus.CANCELLED && (
                    <div className="title-status-card line-height-12">
                      <span className={`text-14 line-height-14 status-label`}>
                        {`Cancelled from ${
                          quotationStatusLabel[
                            quotationDetail.cancelled.fromStatus
                          ]
                        }`}
                      </span>
                    </div>
                  )}
                  <div className="mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-7 pr-0">
                  <div className="d-flex flex-row quotation-detail-information">
                    <div className="position-relative">
                      {quotationDetail.creator.avatar && (
                        <Avatar
                          src={quotationDetail.creator.avatar}
                          user={{ firstname: quotationDetail.creator.name }}
                          radius={40}
                        />
                      )}
                    </div>

                    <div className="d-flex flex-column flex-grow-1 quotation-content-info">
                      <div className="d-flex flex-row flex-grow-1 mb-2">
                        <span
                          className="text-14 line-height-14 font-weight-bold title-hover"
                          title={quotationDetail.title}
                        >
                          {truncateText(quotationDetail.title, 15)}
                        </span>
                      </div>

                      <div className="d-flex flex-row flex-grow-1">
                        <div className="d-flex flex-column flex-grow-1">
                          <div className="line-height-10 text-10">
                            <span className="text-10">
                              {quotationDetail.quotationNumber}
                            </span>
                            &nbsp;/&nbsp; <br />
                            <span className="text-10 font-weight-bold">
                              {quotationDetail.productName}
                            </span>
                          </div>
                          <div className="date-information line-height-10 text-10">
                            <span className="text-10">
                              {defaultDisplayDate(
                                new Date(quotationDetail.createdAt)
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3"></div>
                </div>
                <div className="col-md-5 pl-0">
                  <div className="psp-list pl-4">
                    {/* <span className="line-height-14 font-weight-bold title-hover">Invited PSP</span> */}
                    <ul>
                      <li
                        title={
                          quotationDetail.listPSP[0]
                            ? quotationDetail.listPSP[0].company
                            : '-'
                        }
                      >
                        <span className="text-11">
                          {quotationDetail.listPSP[0]
                            ? truncateText(
                                quotationDetail.listPSP[0].company,
                                12
                              )
                            : '-'}
                        </span>
                      </li>
                      <li
                        title={
                          quotationDetail.listPSP[1]
                            ? quotationDetail.listPSP[1].company
                            : '-'
                        }
                      >
                        <span className="text-11">
                          {quotationDetail.listPSP[1]
                            ? truncateText(
                                quotationDetail.listPSP[1].company,
                                12
                              )
                            : '-'}
                        </span>
                      </li>
                      <li
                        title={
                          quotationDetail.listPSP[2]
                            ? quotationDetail.listPSP[2].company
                            : '-'
                        }
                      >
                        <span className="text-11">
                          {quotationDetail.listPSP[2]
                            ? truncateText(
                                quotationDetail.listPSP[2].company,
                                12
                              )
                            : '-'}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </NavLink>
        </div>
      )}
    </>
  )
}

export default QuotationItem
