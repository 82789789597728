import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import './AddPriceModal.scss'
import * as Icon from 'react-feather'

import * as actions from '../../../store/actions/index'
import Modal from '../../../components/Modal/Modal'
import InputField from '../../../components/Form/InputField/InputField'
import { SubmitPriceAPI } from '../../../api/Rfq/SubmitPricePsp'
import FormHandler from '../../../factory/FormHandler'
import {
  doUploadMedia,
  getPreviewFileSelected,
} from '../../../shared/fileHelper'
import mediaType from '../../../shared/mediaType'
import toastCenter, { toastMessageType } from '../../../shared/toastCenter'

const renderAttachments = (attachments, handleRemoveAttachmentFile) => {
  if (attachments.length === 0) {
    return null
  }
  return (
    <>
      {attachments.map((attachment, idx) => (
        <div
          key={idx}
          className="d-flex flex-row w-100 pb-2 mt-3 align-items-center"
        >
          <img
            src={getPreviewFileSelected(attachment)}
            alt=""
            height={40}
            width={40}
            className="pr-2"
          />
          <div className="pr-2">
            <div>{attachment.name}</div>
            <div className="text-grey">{attachment.type}</div>
          </div>
          <Icon.Trash2
            onClick={() => handleRemoveAttachmentFile(attachments, idx)}
            color="red"
            width={18}
            height={18}
            className="ml-auto cursor-pointer"
          />
        </div>
      ))}
    </>
  )
}

const AddPriceModal = ({
  show,
  setShow,
  apiStart,
  apiStop,
  quotationId,
  user,
  setRefetch,
}) => {
  const [quoteReference, setQuoteReference] = useState('')
  const [printPrice, setPrintPrice] = useState('')
  const [shippingPrice, setShippingPrice] = useState('')
  const [estimatedTurnaroundTime, setEstimatedTurnaroundTime] = useState('')
  const [note, setNote] = useState('')
  const [attachment, setAttachment] = useState([])

  const refAttachment = useRef()
  const handleAddAttachmentClick = () => {
    refAttachment.current.click()
  }
  const handleAddAttachmentFile = (e) => {
    setAttachment([...attachment, ...Array.from(e.target.files)])
  }
  const handleRemoveAttachmentFile = (attachment, i) => {
    const data = [...attachment]
    data.splice(i, 1)
    setAttachment(data)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    const formData = new FormHandler()
    formData.append('quoteReference', quoteReference)
    formData.append('printPrice', printPrice)
    formData.append('shippingPrice', shippingPrice)
    formData.append('estimatedTurnaroundTime', estimatedTurnaroundTime)
    formData.append('note', note)

    const submitPrice = new SubmitPriceAPI()

    const onNext = (response) => {
      if (response.success) {
        // upload attachment
        if (attachment.length > 0) {
          doUploadMedia(response.data._id, attachment, mediaType.RFQ_ATTACHMENT)
            .then((res) => {
              const { failed, responseCollection } = res
              if (responseCollection.length > 0) {
                localStorage.setItem(
                  'temp_uploaded_files',
                  JSON.stringify(responseCollection)
                )
                setRefetch((prevState) => !prevState)
              }

              if (failed.length > 0) {
                toastCenter.message(
                  'Failed while uploading attachment',
                  failed,
                  toastMessageType.WARNING
                )
              }
            })

            .catch((err) => {
              toastCenter.messageServerError()
            })
        }
        toastCenter.message(
          'Price has been submitted',
          'Please wait until your request is approved.'
        )
      } else {
        toastCenter.messageServerErrorCustom(response)
      }
    }

    const onComplete = () => {
      setRefetch((prevState) => !prevState)
      setPrintPrice('')
      setShippingPrice('')
      setEstimatedTurnaroundTime('')
      setNote('')
      setAttachment([])
      setShow(false)
    }

    const onError = () => {
      toastCenter.messageServerError()
    }

    submitPrice.subscribe(
      quotationId,
      formData.all(),
      onNext,
      onComplete,
      onError
    )
    // todo add API call to sumbit version
    // const resp = await axios.post(UPLOAD_ENDPOINT, formData, {
    //   headers: {
    //     "content-type": "multipart/form-data",
    //     Authorization: `Bearer ${userInfo.token}`,
    //   },
    // });
    // setStatus(resp.status === 200 ? "Thank you!" : "Error.");
  }

  return (
    <Modal
      show={show}
      onHideModal={setShow}
      modalSize="modal-md"
      isForceShow
      useClose={false}
    >
      <div className="container-add-price position-relative">
        <form className="layout" onSubmit={handleSubmit}>
          <div>
            <h1>PSP Response</h1>
            <hr />
          </div>
          <div className="body-row">
            <InputField
              isRequired
              label="Quote Reference"
              maxLength={30}
              onChange={(e) => setQuoteReference(e.target.value)}
              value={quoteReference}
            />
            <InputField
              label="Print Price (USD)"
              maxLength={15}
              isRequired
              type="number"
              onChange={(e) => setPrintPrice(e.target.value)}
              value={printPrice}
            />
            <InputField
              label="Shipping Price (USD)"
              maxLength={15}
              isRequired
              type="number"
              onChange={(e) => setShippingPrice(e.target.value)}
              value={shippingPrice}
            />
            <InputField
              label="Estimated Turnaround Time (Business Days)"
              maxLength={2}
              isRequired
              type="number"
              onChange={(e) => setEstimatedTurnaroundTime(e.target.value)}
              onKeyDown={(event) => {
                const key = event.key
                if (
                  event.keyCode === 8 ||
                  event.keyCode === 46 ||
                  (event.keyCode >= 37 && event.keyCode <= 40)
                ) {
                  return
                }

                if (
                  !/^[1-9]$/.test(key) &&
                  !(key === '0' && event.target.value.length > 0)
                ) {
                  event.preventDefault()
                }
              }}
              value={estimatedTurnaroundTime}
            />
            <InputField
              label="Add Note"
              maxLength={255}
              onChange={(e) => setNote(e.target.value)}
              value={note}
            />
            <div className="attachment">
              <label htmlFor="title" className="mb-0">
                Attachments (Die Lines, Images of product type, etc)
              </label>
              <div className="icon" onClick={handleAddAttachmentClick}>
                <Icon.PlusCircle height={18} width={18} />
                <input
                  ref={refAttachment}
                  type="file"
                  className="input"
                  onChange={handleAddAttachmentFile}
                />
              </div>
            </div>
            {attachment.length === 0 && (
              <div
                onDragOver={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
                onDrop={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  const files = e.dataTransfer.files
                  if (files && files.length > 0) {
                    setAttachment([...attachment, ...Array.from(files)])
                  }
                }}
                className="mt-3 dropzone"
              >
                <div htmlFor="fileInput">
                  <div className="label-file">
                    <div
                      className="choose-files"
                      onClick={handleAddAttachmentClick}
                    >
                      Choose files
                    </div>{' '}
                    or drag and drop file here
                  </div>
                  <div className="supported-files">
                    Supported files: <br />
                    .csv, .doc, .docx, .jpeg, .jpg, .pdf, .txt, .xls, .xlsx,
                    .zip <br />
                  </div>
                  <div className="max-file-size">Maximum File Size 500 MB</div>
                </div>
              </div>
            )}
            {renderAttachments(attachment, handleRemoveAttachmentFile)}
          </div>
          <div className="footer-row mt-4">
            <button
              className="btn btn-darkprimary"
              type="submit"
              disabled={!(printPrice && shippingPrice)}
            >
              Submit
            </button>
            <button
              className="btn btn-secondary mr-3"
              type="button"
              onClick={() => setShow(false)}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

AddPriceModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
}

const mapStateToProps = ({ user }) => {
  return { user }
}

const mapDispatchToProps = (dispatch) => {
  return {
    apiStart: () => dispatch(actions.apiStart()),
    apiStop: () => dispatch(actions.apiStop()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPriceModal)
