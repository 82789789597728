import { RequestTokenAPI } from '../api/Commons/RequestToken'
import toastCenter from './toastCenter'
import clientLogin from './clientLogin'
import { returnCustomerToPpm } from './utility'

export const requestTokenSourceType = {
  UPLOAD: 'upload',
  RESELLER_CUSTOMER: 'reseller-customer',
  RESELLER_PROFILE: 'reseller-profile',

  GUEST_ANNOTATION: 'guest-annotation',

  // available only on customer
  CUSTOMER_CARD: 'customer-card',
  CUSTOMER_TEAM_MEMBER: 'customer-team-member',
  CUSTOMER_PACKAGE: 'customer-package',
  CUSTOMER_PAYMENT_METHOD: 'customer-payment-method',
  CUSTOMER_TRANSACTION: 'customer-transaction',
  CUSTOMER_PROFILE: 'customer-profile',

  // available for user & customer login type
  CART: 'cart',
  FILTER: 'filter',
  CARD: 'card',
  CARD_ANNOTATION: 'card-annotation',
  PROFILE: 'profile',
  CRETATE_PEXELS_IMAGES: 'create-pexels-images',
}

export const requestTokenSourceByLogin = (type) => {
  const clientId = localStorage.getItem('clientId')
  return clientId === clientLogin.CUSTOMER
    ? `${clientLogin.CUSTOMER}-${type}`
    : `${clientLogin.ADMIN}-${type}`
}

export const requestAccessToken = (type, cb = null, user = null) => {
  const requestTokenAPI = new RequestTokenAPI()

  const onNext = (response) => {
    if (response.success) {
      if (typeof cb === 'function') {
        return cb(response.data)
      }

      return response.data
    }

    return toastCenter.messageServerError()
  }
  const onComplete = () => {}
  const onError = () => {
    toastCenter.messageServerError()
    if (user != null) returnCustomerToPpm(user)
  }
  requestTokenAPI.subscribe(type, onNext, onComplete, onError)
}
