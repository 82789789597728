import { Observable } from 'rxjs'
import AxiosSubscriber from '../../factory/AxiosSubscriber'

export class DeleteAttachmentAPI {
  constructor() {
    this.subscription = null
  }

  subscribe(quotationPspId, quotationPspAttachmentId, next, complete, error) {
    const token = localStorage.getItem('token')

    const httpConfig = {
      url: `/rfq/${quotationPspId}/${quotationPspAttachmentId}/attachment-delete`,
      method: 'delete',
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    let observable$ = new Observable((observer) => {
      return new AxiosSubscriber(observer, httpConfig)
    })

    this.subscription = observable$.subscribe({
      next: next,
      complete: complete,
      error: error,
    })
  }

  unsubscribe() {
    if (this.subscription !== undefined && this.subscription !== null) {
      this.subscription.unsubscribe()
    }
  }
}
