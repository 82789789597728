import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import './AddShipment.scss'
import * as actions from '../../../store/actions/index'
import Modal from '../../../components/Modal/Modal'
import InputField from '../../../components/Form/InputField/InputField'
import { UpdateShipmentPspAPI } from '../../../api/Rfq/UpdateShipmentPsp'
import FormHandler from '../../../factory/FormHandler'
import toastCenter from '../../../shared/toastCenter'
const AddShipmentModal = ({ show, setShow, setShipment, quotationOrderId }) => {
  const [shipmentId, setShipmentId] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault()
    const formData = new FormData()
    formData.append('printPrice', shipmentId)
    // todo add API call to sumbit version
    // const resp = await axios.post(UPLOAD_ENDPOINT, formData, {
    //   headers: {
    //     "content-type": "multipart/form-data",
    //     Authorization: `Bearer ${userInfo.token}`,
    //   },
    // });
    // setStatus(resp.status === 200 ? "Thank you!" : "Error.");
  }

  const handleSubmitShipmentId = () => {
    setIsLoading(true)
    const data = new FormHandler()
    data.append('shipmentId', shipmentId)

    const updateShipmentPspAPI = new UpdateShipmentPspAPI()
    const onNext = (response) => {
      if (response.success) {
        setShipment(shipmentId)
        toastCenter.message(
          'Shipment ID set',
          'The shipment ID has been set successfully.'
        )
      } else {
        toastCenter.messageServerError()
      }
    }
    const onComplete = () => {
      setIsLoading(false)
      setShow(false)
    }
    const onError = () => {
      toastCenter.messageServerError()
    }

    updateShipmentPspAPI.subscribe(
      quotationOrderId,
      data.all(),
      onNext,
      onComplete,
      onError
    )
  }

  return (
    <Modal
      show={show}
      onHideModal={setShow}
      modalSize="modal-md"
      isForceShow
      useClose={false}
    >
      <div className="container-add-shipment position-relative">
        <form className="layout" onSubmit={handleSubmit}>
          <div className="body-row">
            <InputField
              label="Input your Shipment ID"
              maxLength={15}
              isRequired
              placeholder="Shipment ID"
              onChange={(e) => setShipmentId(e.target.value)}
              value={shipmentId}
            />
          </div>
          <div className="footer-row">
            <button
              onClick={handleSubmitShipmentId}
              className={`btn ${
                !shipmentId ? 'btn-secondary' : 'btn-darkprimary'
              }  text-white`}
              type="submit"
              disabled={!shipmentId || isLoading}
            >
              Submit
            </button>
            <button
              className="btn btn-secondary mr-3"
              type="button"
              onClick={() => setShow(false)}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

AddShipmentModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
}

const mapStateToProps = ({ user }) => {
  return { user }
}

const mapDispatchToProps = (dispatch) => {
  return {
    apiStart: () => dispatch(actions.apiStart()),
    apiStop: () => dispatch(actions.apiStop()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddShipmentModal)
