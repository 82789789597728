export const notSupport = '/not-support'
export const clearhHistory = '/clear-history'
export const signin = '/signin'
export const forgotPassword = '/forgot-password'
export const resetPassword = '/reset-password'
export const verifyEmail = '/email-verification'
export const signinAdmin = '/admin/signin'
export const signout = '/signout'
export const guestAnnotation = '/annotation/guestreviewer'
export const home = '/'
export const createOrder = '/orders/new'
// export const editDraftOrder = "/orders/draft/:orderId";
export const cloneOrder = '/orders/clone/:orderId'
export const orderDetail = '/orders/detail/:orderId'
export const attachmentDetail =
  '/orders/detail/:orderId/attachments/:attachmentId'
export const versionDetail =
  '/orders/detail/:orderId/attachments/:attachmentId/versions/:versionId'
export const teamMember = '/team-members'
export const editTeamMember = '/team-members/:memberId'
export const generalSetting = '/settings'
export const paymentMethod = '/billing/payments'
export const editPaymentMethod = '/billing/payments/:paymentId'
export const invoices = '/billing/invoices'
export const payments = '/billing/payments'
export const invoiceDetail = '/billing/invoices/:invoiceId'
export const cart = '/checkout/cart'
export const checkoutProcess = '/checkout/process'
export const checkoutProcessInvoice = '/checkout/process/invoice/:invoiceId'
export const manageProfile = '/profile'
export const manageService = '/membership/service'
export const managePackage = '/membership/package'
export const manageStorage = '/membership/storage'
export const manageAllocatedHours = '/membership/allocated-hours'
export const createCustomer = '/customer/new'
export const customerDetail = '/customer/:customerId'
export const archivedOrder = '/archived-order'
export const myImages = '/my-images'
export const impersonateCustomer = '/impersonate-customer'
export const impersonateRfq = '/impersonate-rfq/:quotationId'
export const retail = '/retail'
export const auth0Login = '/auth0_login'
export const auth0Validate = '/auth0_validate'
export const usageReporting = '/usage-reporting'
export const reportUsage = '/usage-reporting/usage/:customerId'
export const activeOrderAssignment = '/active-order-assignment'
export const printServiceProvider = '/print-service-provider'
export const editServiceProvider = '/print-service-provider/:pspId'
export const quotationDashboard = '/quotation'
export const createQuotation = '/create-quotation'
export const quotationDetail = '/quotation/detail/:quotationId'
export const duplicateQuotation = '/duplicate-quotation/:quotationId/:versionId'
export const requestForQuotation = '/rfq/:quotationId'
export const archivedQuotation = '/archived-quotation'
export const workflowAndRoles = '/workflow-and-roles'
export const createWorkflowAndRoles = '/workflow-and-roles/create'
export const editWorkflowAndRoles = '/workflow-and-roles/:workflowId'
export const oldKanban = '/old-kanban'
export const orderListView = '/order-list'
export const pdfToolsDemo = '/pdf-tools-demo'
