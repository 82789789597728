import itemTypes from './itemTypes'
import userRole from './userRole'
import theme from './theme'

export default [
  {
    label: 'Awaiting Quote',
    value: itemTypes.AWAITING_QUOTE,
    name: 'awaiting-quote',
    color: theme.DARK,
    allowApprover: false,
    access: {
      [userRole.OPERATOR]: [],
      [userRole.MANAGER]: [],
      [userRole.CREATIVE_MANAGER]: [],
      [userRole.PRODUCTION_MANAGER]: [],
      [userRole.PARTNER_PRODUCTION_MANAGER]: [
        'awaiting-approval',
        'rejected',
        'staging',
      ],
      [userRole.PARTNER_OPERATOR]: [],
      [userRole.OWNER]: [],
      [userRole.STAFF]: [],
    },
    selectedBackgroundColor: theme.DARKBLUE,
    selectedColor: '#fff',
  },
  {
    label: 'Awaiting Approval',
    value: itemTypes.AWAITING_APPROVAL,
    name: 'awaiting-approval',
    color: theme.DARK,
    allowApprover: false,
    access: {
      [userRole.OPERATOR]: [],
      [userRole.MANAGER]: [],
      [userRole.CREATIVE_MANAGER]: [],
      [userRole.PRODUCTION_MANAGER]: [],
      [userRole.PARTNER_PRODUCTION_MANAGER]: [
        'awaiting-quote',
        'rejected',
        'staging',
      ],
      [userRole.PARTNER_OPERATOR]: [],
      [userRole.OWNER]: [],
      [userRole.STAFF]: [],
    },
    selectedBackgroundColor: theme.DARKBLUE,
    selectedColor: '#fff',
  },
  {
    label: 'Rejected',
    value: itemTypes.REJECTED,
    name: 'rejected',
    color: theme.DARK,
    allowApprover: false,
    access: {
      [userRole.OPERATOR]: [],
      [userRole.MANAGER]: [],
      [userRole.CREATIVE_MANAGER]: [],
      [userRole.PRODUCTION_MANAGER]: [],
      [userRole.PARTNER_PRODUCTION_MANAGER]: [
        'awaiting-quote',
        'awaiting-approval',
        'staging',
      ],
      [userRole.PARTNER_OPERATOR]: [],
      [userRole.OWNER]: [],
      [userRole.STAFF]: [],
    },
    selectedBackgroundColor: theme.DARKBLUE,
    selectedColor: '#fff',
  },
  {
    label: 'Staging',
    value: itemTypes.STAGING,
    name: 'staging',
    color: theme.DARK,
    allowApprover: false,
    access: {
      [userRole.OPERATOR]: [],
      [userRole.MANAGER]: ['before-ready-to-go'],
      [userRole.CREATIVE_MANAGER]: ['before-ready-to-go'],
      [userRole.PRODUCTION_MANAGER]: ['before-ready-to-go'],
      [userRole.PARTNER_PRODUCTION_MANAGER]: [
        'awaiting-quote',
        'awaiting-approval',
        'rejected',
        'before-ready-to-go',
      ],
      [userRole.PARTNER_OPERATOR]: [],
      [userRole.OWNER]: [],
      [userRole.STAFF]: [],
    },
    selectedBackgroundColor: theme.DARKBLUE,
    selectedColor: '#fff',
  },
  {
    label: 'Order Review',
    value: itemTypes.BEFORE_READY_TO_GO,
    name: 'before-ready-to-go',
    color: theme.DARK,
    allowApprover: false,
    access: {
      [userRole.OPERATOR]: [],
      [userRole.MANAGER]: ['staging'],
      [userRole.CREATIVE_MANAGER]: ['staging'],
      [userRole.PRODUCTION_MANAGER]: ['staging'],
      [userRole.PARTNER_PRODUCTION_MANAGER]: [
        'awaiting-quote',
        'awaiting-approval',
        'rejected',
        'staging',
      ],
      [userRole.PARTNER_OPERATOR]: [],
      [userRole.OWNER]: [],
      [userRole.STAFF]: [],
    },
    selectedBackgroundColor: theme.DARKBLUE,
    selectedColor: '#fff',
  },
  {
    label: 'Ready To Start',
    value: itemTypes.READY_TO_GO,
    name: 'ready-to-go',
    color: theme.DARK,
    allowApprover: false,
    access: {
      [userRole.OPERATOR]: ['in-progress'],
      [userRole.MANAGER]: ['in-progress'],
      [userRole.CREATIVE_MANAGER]: ['in-progress'],
      [userRole.PRODUCTION_MANAGER]: ['in-progress'],
      [userRole.PARTNER_PRODUCTION_MANAGER]: ['in-progress'],
      [userRole.PARTNER_OPERATOR]: ['in-progress'],
      [userRole.OWNER]: [],
      [userRole.STAFF]: [],
    },
    selectedBackgroundColor: theme.DARKBLUE,
    selectedColor: '#fff',
  },
  {
    label: 'In Progress',
    value: itemTypes.IN_PROGRESS,
    name: 'in-progress',
    allowApprover: false,
    color: theme.DARK,
    access: {
      [userRole.OPERATOR]: ['in-review'],
      [userRole.MANAGER]: ['in-review'],
      [userRole.CREATIVE_MANAGER]: ['in-review'],
      [userRole.PRODUCTION_MANAGER]: ['in-review'],
      [userRole.PARTNER_PRODUCTION_MANAGER]: ['in-review'],
      [userRole.PARTNER_OPERATOR]: ['in-review'],
      [userRole.OWNER]: [],
      [userRole.STAFF]: [],
    },
    selectedBackgroundColor: theme.DARKBLUE,
    selectedColor: '#fff',
  },
  {
    label: 'GR Revision',
    value: itemTypes.GR_REVISION,
    name: 'gr-revision',
    allowApprover: false,
    color: theme.DARK,
    access: {
      [userRole.OPERATOR]: [],
      [userRole.MANAGER]: ['in-review'],
      [userRole.CREATIVE_MANAGER]: ['in-review'],
      [userRole.PRODUCTION_MANAGER]: ['in-review'],
      [userRole.PARTNER_PRODUCTION_MANAGER]: ['in-review'],
      [userRole.PARTNER_OPERATOR]: [],
      [userRole.OWNER]: [],
      [userRole.STAFF]: [],
    },
    selectedBackgroundColor: theme.DARKBLUE,
    selectedColor: '#fff',
  },
  {
    label: 'In Review',
    value: itemTypes.IN_REVIEW,
    name: 'in-review',
    color: theme.DARK,
    allowApprover: false,
    access: {
      [userRole.OPERATOR]: [],
      [userRole.MANAGER]: ['in-progress', 'approved'],
      [userRole.CREATIVE_MANAGER]: ['in-progress', 'approved'],
      [userRole.PRODUCTION_MANAGER]: ['in-progress', 'approved'],
      [userRole.PARTNER_PRODUCTION_MANAGER]: ['in-progress', 'approved'],
      [userRole.PARTNER_OPERATOR]: [],
      [userRole.OWNER]: ['in-progress', 'approved'],
      [userRole.STAFF]: ['in-progress', 'approved'],
      [userRole.WHITELABEL_MEMBER]: ['in-progress', 'approved'],
    },
    selectedBackgroundColor: theme.DARKBLUE,
    selectedColor: '#fff',
  },
  {
    label: 'On Hold',
    value: itemTypes.ON_HOLD,
    name: 'on-hold',
    color: theme.ORANGE,
    allowApprover: false,
    access: {},
    selectedBackgroundColor: theme.ORANGE,
    selectedColor: '#fff',
  },
  {
    label: 'Approved',
    value: itemTypes.APPROVED,
    name: 'approved',
    color: theme.DARKBLUE,
    allowApprover: true,
    access: {
      [userRole.OPERATOR]: [],
      [userRole.MANAGER]: ['in-review'],
      [userRole.CREATIVE_MANAGER]: ['in-review', 'completed'],
      [userRole.PRODUCTION_MANAGER]: ['in-review', 'completed'],
      [userRole.PARTNER_PRODUCTION_MANAGER]: ['in-review', 'completed'],
      [userRole.PARTNER_OPERATOR]: [],
      [userRole.OWNER]: ['in-review'],
      [userRole.STAFF]: ['in-review'],
      [userRole.WHITELABEL_MEMBER]: ['in-review'],
    },
    selectedBackgroundColor: theme.DARKBLUE,
    selectedColor: '#fff',
  },
  {
    label: 'Completed',
    value: itemTypes.COMPLETED,
    name: 'completed',
    color: theme.DARKBLUE,
    allowApprover: true,
    access: {
      [userRole.OPERATOR]: [],
      [userRole.MANAGER]: [],
      [userRole.CREATIVE_MANAGER]: ['approved'],
      [userRole.PRODUCTION_MANAGER]: ['approved'],
      [userRole.PARTNER_PRODUCTION_MANAGER]: ['approved'],
      [userRole.PARTNER_OPERATOR]: [],
      [userRole.OWNER]: [],
      [userRole.STAFF]: [],
      [userRole.WHITELABEL_MEMBER]: [],
    },
    selectedBackgroundColor: theme.DARKBLUE,
    selectedColor: '#fff',
  },
  {
    label: 'Cancelled',
    value: itemTypes.CANCELLED,
    name: 'cancelled',
    color: theme.RED,
    allowApprover: false,
    access: {},
    selectedBackgroundColor: theme.RED,
    selectedColor: '#fff',
  },
]
