import { Observable } from 'rxjs'
import AxiosSubscriber from '../../factory/AxiosSubscriber'

export class ValidateAuth0API {
  constructor() {
    this.subscription = null
  }

  subscribe(token, data, next, complete, error) {
    const httpConfig = {
      url: '/d/validate-auth0-token',
      method: 'post',
      data: data,
      headers: {
        'x-csrf': '2aYpLhgiXeQ68gErix7u2Ytt66W',
        accept: 'application/json',
        'Content-Type': `application/json`,
        Authorization: `Bearer ${token}`,
      },
    }

    let observable$ = new Observable((observer) => {
      return new AxiosSubscriber(observer, httpConfig, false)
    })

    this.subscription = observable$.subscribe({
      next: next,
      complete: complete,
      error: error,
    })
  }

  unsubscribe() {
    if (this.subscription !== undefined && this.subscription !== null) {
      this.subscription.unsubscribe()
    }
  }
}
