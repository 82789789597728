export default {
  OWNER: 'owner',
  STAFF: 'staff',
  OPERATOR: 'operator',
  MANAGER: 'manager',
  CREATIVE_MANAGER: 'creative_manager',
  PRODUCTION_MANAGER: 'production_manager',
  GUEST: 'guest',
  CSO: 'cso',
  WHITELABEL_MEMBER: 'whitelabel_member',
  WHITELABEL: 'whitelabel',
  PARTNER_PRODUCTION_MANAGER: 'partner_pm',
  PARTNER_OPERATOR: 'partner_operator',
}
